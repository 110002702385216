import React from 'react'
import Logimage from "../images/logo.jpg";
import Submitimage from "../images/submit.png";
import $ from 'jquery';
import DashboardTopbar from './DashboardTopbar';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Inputquote() {

    var allfields = ['bank_statement', 'broker_fee', 'daily_payment', 'estmated_month_rev', 'estimat_terms', 'gross_fund', 'origin_fee', 'est_annul_percnt_rate', 'estim_month_cost', 'estim_total_pay_amt', 'fin_charg', 'net_fnd', 'pay_bk_bal', 'spec_percent', 'tot_dol_cos', 'all_other_fees','broker_comp'];

    var california = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13];
    var connecticut = [0, 2, 3, 4, 5, 6, 8, 9, 10, 11, 13, 15];
    var florida = [2, 3, 4, 5, 6, 9, 11, 13, 14];
    var georgia = [2, 3, 4, 5, 6, 9, 11, 13, 14];
    var kansas = [0, 2, 3, 4, 5, 6, 9, 10, 11, 13, 14];
    var ny = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13];
    var utah = [1, 2, 3, 4, 5, 6, 9, 11, 13, 14];
    var virginia = [16, 2, 4, 5, 6, 9, 10, 11];
    var planstsflds = localStorage.getItem('uplan');
    var broker_compensation_sts = 'No value';


    const navigate = useNavigate();

    useEffect(() => {
        var usrid = localStorage.getItem('uid');
        var merchantid = localStorage.getItem('marchid');
        var paymenttype = localStorage.getItem('paymentduration');

        var orgfees = localStorage.getItem('origination_fee');//orgfee
        

        if (usrid === null) {
            // navigate('/login');
        }
        var len = allfields.length;
        for (var i = 0; i < len; i++) {
            var trgid = allfields[i];
            var popdivs = trgid + '_prev';
            $('#' + popdivs).css('display', 'none')
            $('#' + trgid + ' .cinput').val('0');
            $('#' + trgid + ' .winput').val('0');
        }
        if (planstsflds === 'Basic Pro' || planstsflds === 'Premium') {
            $('.clientid').css('display', 'block');
            $('#generate').text('Send');
            var mid = localStorage.getItem('mrchid');
            $('#marchid').text('Merchant ID : ' + mid);
        } else {
            $('.clientid').css('display', 'none');
            $('#marchid').css('display', 'none');
        }

        $('#payment_type').val(paymenttype);
        $('#coverorgfee').val(orgfees);
    })

    var stateurl = '';
    const [values, setValues] = useState({ field1: '', field2: '' });
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Only update the value if it's a number or an empty string
        if (/^\d*$/.test(value)) {
            setValues({
                ...values,
                [name]: value
            });
        }
    };




    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return '';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }


    function retdurntonumber(showid){
        let usd = $('#'+showid).val();
        if(usd!==''){
        let normalNumber = parseFloat(usd.replace(/[$,]/g, ''));
        $('#'+showid).val(normalNumber);
        }

    }

    function calculateallvalues(workid, showid) {
       
        if (workid === 'noval' && showid === 'showid') {
            //no action
        }else if (workid === 'noval' && showid === 'noval') {
             //no action
        } else {
            var vltoshow = $('#' + showid).val();

            $('#'+workid).val(vltoshow)
            if (vltoshow === '') {
                $('#' + workid).val('0.00');
            }
            retdurntonumber(workid)
            
        }
        calculatefieldesforautoprint(workid, showid)
    }


    function calculatefieldesforautoprint(workid, showid) {
        if (workid === 'noval' && showid === 'showid') {
            //no action
        }else if (workid === 'noval' && showid === 'noval') {
             //no action
        } else {
        retdurntonumber(showid)
        
        var vltoshow = $('#' + showid).val();
           var formatvalue = formatMoney(vltoshow);
           $('#' + showid).val(formatvalue);
           
        }

            var grossfund = $('#grossfuncing').val();
            if (grossfund == '') {
                grossfund = 0
            }
            var grossfunding = parseInt(grossfund);
            var est_month_rev = $('#estimatemonthly').val();
            if (est_month_rev == '') {
                est_month_rev = 0;
            }
            var est_month_renv = Number(est_month_rev);
            var dailypay = $('#dailypayment').val();
            if (dailypay == '') {
                dailypay = 0
            }
            var dailypayment = Number(dailypay);
            var banksts = $('#bankstatement').val();
            if (banksts == '') {
                banksts = 0
            }
            var bank_statement = parseInt(banksts);
            var orgf = $('#orgfee').val();
            if (orgf == '') {
                orgf = 0
            }
            var orgfee = parseInt(orgf);
            var brofee = $('#brokerfee').val();
            if (brofee == '') {
                brofee = 0
            }
            var broker_fee = parseInt(brofee);
            var estterm = $('#estimatedterms').val();
            var estimatedterm = Number(estterm);

            var payof = $('#payoffamt').val();
            if (payof == '') {
                payof = 0
            }
            var payoffamnt = parseInt(payof);



            var specified_percent = 0;
            var net_funding = grossfunding - (orgfee + payoffamnt);
            var finance_chagrge = (estimatedterm * dailypayment) - grossfunding;
            var payback_bal = estimatedterm * dailypayment;
            var estimated_total_payment_amt = estimatedterm * dailypayment;
            var total_doller = (estimatedterm * dailypayment) - grossfunding;
            var pmt_type = $('#payment_type').val();
            if (pmt_type === 'Daily') {
                specified_percent = (dailypayment * 21) / est_month_renv;
            } else if (pmt_type === 'Weekly') {
                specified_percent = ((dailypayment / 5) * 21) / est_month_renv;
            } else if (pmt_type === 'Bi-Weekly') {
                specified_percent = ((dailypayment / 10) * 21) / est_month_renv;
            }

            var spec_perc = specified_percent.toFixed(2);
            var estimated_month_cost = 0;
            if (pmt_type === 'Daily') {
                estimated_month_cost = dailypayment * 21;
            }else if (pmt_type === 'Weekly') {
                estimated_month_cost = dailypayment * 4;
            }else if (pmt_type === 'Bi-Weekly') {
                estimated_month_cost = dailypayment * 2;
            }
            var estapr = 0;
            if (pmt_type === 'Daily') {
                estapr = ((((payback_bal - net_funding) / grossfunding) * 365) / estimatedterm) * 100;
            }else if (pmt_type === 'Weekly') {
                var dayvalue = estimatedterm*5;
                estapr = ((((payback_bal - net_funding) / grossfunding) * 365) / dayvalue) * 100;
            }else if (pmt_type === 'Bi-Weekly') {
                var byweekdayvalue = estimatedterm*10
                estapr = ((((payback_bal - net_funding) / grossfunding) * 365) / byweekdayvalue) * 100;
            }
            var aprpercent = estapr.toFixed(2);

            var fc = formatMoney(finance_chagrge);
            $('#financecharge').val(fc);
            var tdc = formatMoney(total_doller);
            $('#totaldollercost').val(tdc);
            var nf = formatMoney(net_funding);
            $('#netfunding').val(nf);
            var etpa = formatMoney(estimated_total_payment_amt);
            $('#estimatetotalpay').val(etpa);
            var pbb = formatMoney(payback_bal);
            $('#paybackbal').val(pbb);
            var spr = ((specified_percent) * 100).toFixed(2);
            $('#specifiedper').val(spr + '%');
            var emc = formatMoney(estimated_month_cost);
            $('#estimatedcost').val(emc);
            $('#apr').val(aprpercent + '%');

        
    }
    var disclosurebalence = 0;
    function previewall() {
        disclosurebalence = parseInt(localStorage.getItem('updisclo'));
        if (disclosurebalence > 0) {

            var brrowersstate = $('#brrowersstate').val();
            $('#brrowersstateprev').text(brrowersstate);
            if (brrowersstate === 'Virginia') {
                var recipient_name = $('#recipient_name').val();
                $('#recipient_nameprev').text(recipient_name);
                $('.receipen_name').css('display', 'block')

                var recipient_address = $('#recipient_address').val();
                $('#recipient_addressprev').text(recipient_address);
                $('.recipient_address').css('display', 'block')
            }
            var mcavalue = $('#mcavalue').val();
            $('#mcavalueprev').text(mcavalue);
            if (mcavalue === 'Buyout') {
                var fndname = $('#putfundername').val();
                $('#putfundernameprev').text(fndname);
                $('.funder_name').css('display', 'block')

                var payamt = $('#coverpayoffamt').val();
                $('#payoffamtprev').text(payamt);
                $('.payoff_amt').css('display', 'block')

                var payds = $('#payoffdes').val();
                $('#payoffdesprev').text(payds);
                $('.payoff_desc').css('display', 'block')
            }
            if (mcavalue === 'Renewal') {
                var payamt = $('#coverpayoffamt').val();
                $('#payoffamtprev').text(payamt);
                $('.payoff_amt').css('display', 'block')

                var payds = $('#payoffdes').val();
                $('#payoffdesprev').text(payds);
                $('.payoff_desc').css('display', 'block')
            }
            var grossfuncing = $('#covergross').val();
            $('#covergrossprev').text(grossfuncing);

            var estimatemonthly = $('#coverestimatemonth').val();
            $('#coverestimatemonthprev').text(estimatemonthly);

            var dailypayment = $('#coverdailypayment').val();
            $('#coverdailypaymentprev').text(dailypayment);

            var bankstatement = $('#bankstatement').val();
            $('#bankstatementprev').text(bankstatement);

            var orgfee = $('#coverorgfee').val();
            $('#coverorgfeeprev').text(orgfee);

            var brokerfee = $('#coverbrokerfee').val();
            $('#coverbrokerfeeprev').text(brokerfee);

            var estimatedterms = $('#estimatedterms').val();
            $('#estimatedtermsprev').text(estimatedterms);

            var financecharge = $('#financecharge').val();
            $('#financechargeprev').text(financecharge);

            var totaldollercost = $('#totaldollercost').val();
            $('#totaldollercostprev').text(totaldollercost);

            var netfunding = $('#netfunding').val();
            $('#netfundingprev').text(netfunding);

            var estimatetotalpay = $('#estimatetotalpay').val();
            $('#estimatetotalpayprev').text(estimatetotalpay);

            var paybackbal = $('#paybackbal').val();
            $('#paybackbalprev').text(paybackbal);

            var specifiedper = $('#specifiedper').val();
            $('#specifiedperprev').text(specifiedper);

            var estimatedcost = $('#estimatedcost').val();
            $('#estimatedcostprev').text(estimatedcost);

            var apr = $('#apr').val();
            $('#aprprev').text(apr);

            $('#previewpop').css('display', 'block');
        } else {
            showalert('You have not sufficient balance to generate a disclosure.')
        }
    }

    function closepop() {
        $('#previewpop').css('display', 'none');
    }
    function closepopalert() {
        $('.poptopay').css('display', 'none');
        closepop();
    }

    function showalert(str) {
        $('.poptopay').css('display', 'block');
        $('#msg').text(str);
    }
    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function showhidesplfld(id) {
        var valstate = $('#' + id).val();
        if (valstate === 'Virginia' || valstate === 'Connecticut') {
            $('#recipentname').css('display', 'block');
            $('#recipentaddress').css('display', 'block');
        } else {
            $('#recipentname').css('display', 'none');
            $('#recipentaddress').css('display', 'none');
        }
        prepareforshowHideFields(valstate)
    }

    function prepareforshowHideFields(valsts) {
        var len = allfields.length;
        for (var i = 0; i < len; i++) {
            var trgid = allfields[i];
            var popdivs = trgid + '_prev';
            $('#' + popdivs).css('display', 'none')
            $('#' + trgid).css('display', 'none');
            $('#' + trgid + ' .cinput').val('0');
            $('#' + trgid + ' .winput').val('0');
        }
        showHideFields(valsts)
    }

    function showHideFields(valsts) {
        if (valsts === 'California') {
            activatefields(california);
        }
        if (valsts === 'Connecticut') {
            activatefields(connecticut);
        }
        if (valsts === 'Florida') {
            activatefields(florida);
        }
        if (valsts === 'Georgia') {
            activatefields(georgia);
        }
        if (valsts === 'Kansas') {
            activatefields(kansas);
        }
        if (valsts === 'New York') {
            activatefields(ny);
        }
        if (valsts === 'Utah') {
            activatefields(utah);
        }
        if (valsts === 'Virginia') {
            activatefields(virginia);
        }

    }

    function activatefields(arr) {
        var len = arr.length;
        for (var i = 0; i < len; i++) {
            var nom = arr[i];
            var trgid = allfields[nom];
            var popdivs = trgid + '_prev';
            $('#' + popdivs).css('display', 'block');
            $('#' + trgid).css('display', 'block');
            $('#' + trgid + ' .cinput').val('');
            $('#' + trgid + ' .winput').val('');
        }

    }
    function sendvalues() {
        var fndname = '';
        var payamt = ''
        var payds = '';
        var business_name = 'Not require';
        var businessowner_fname = 'Not require';
        var businessowner_lname = 'Not require';
        var businessowner_email = 'Not require';
        var merchantid = 'Not require';


        $('#previewpop').css('display', 'none');
        var luid = localStorage.getItem('uid');
        var brrowersstate = $('#brrowersstate').val();
        var mcavalue = $('#mcavalue').val();
        if (mcavalue === 'Buyout') {
            fndname = $('#putfundername').val();
            payamt = $('#coverpayoffamt').val();
            payds = $('#payoffdes').val();
        }
        if (mcavalue === 'Renewal') {
            fndname = '';
            payamt = $('#coverpayoffamt').val();
            payds = $('#payoffdes').val();
        }
        if (planstsflds === 'Basic Pro' || planstsflds === 'Premium') {
            business_name = $('#business_name').val();
            businessowner_fname = $('#businessowner_fname').val();
            businessowner_lname = $('#businessowner_lname').val();
            businessowner_email = $('#businessowner_email').val();
            merchantid = localStorage.getItem('marchid');
        }
        var grossfuncing = $('#covergross').val();
        var estimatemonthly = $('#coverestimatemonth').val();
        var dailypayment = $('#coverdailypayment').val();
        var bankstatement = $('#bankstatement').val();
        var orgfee = $('#coverorgfee').val();
        var brokerfee = $('#coverbrokerfee').val();
        var estimatedterms = $('#estimatedterms').val();
        var financecharge = $('#financecharge').val();
        var totaldollercost = $('#totaldollercost').val();
        var netfunding = $('#netfunding').val();
        var estimatetotalpay = $('#estimatetotalpay').val();
        var paybackbal = $('#paybackbal').val();
        var specifiedper = $('#specifiedper').val();
        var estimatedcost = $('#estimatedcost').val();
        var recipient_name = $('#recipient_name').val();
        var recipient_address = $('#recipient_address').val();
        var apr = $('#apr').val();
        var payment_type = $('#payment_type').val();
        var org_description = $('#org_description').val();


        if (brrowersstate === '') {
            offTheBar('brrowersstate')
        } else if (mcavalue === '') {
            offTheBar('mcavalue')
        } else if (grossfuncing === '') {
            offTheBar('grossfuncing')
        } else if (estimatemonthly === '') {
            offTheBar('estimatemonthly')
        } else if (dailypayment === '') {
            offTheBar('dailypayment')
        } else if (bankstatement === '') {
            offTheBar('bankstatement')
        } else if (orgfee === '') {
            offTheBar('orgfee')
        } else if (brokerfee === '') {
            offTheBar('brokerfee')
        } else if (estimatedterms === '') {
            offTheBar('estimatedterms')
        } else if (financecharge === '') {
            offTheBar('financecharge')
        } else if (totaldollercost === '') {
            offTheBar('totaldollercost')
        } else if (netfunding === '') {
            offTheBar('netfunding')
        } else if (estimatetotalpay === '') {
            offTheBar('estimatetotalpay')
        } else if (paybackbal === '') {
            offTheBar('paybackbal')
        } else if (specifiedper === '') {
            offTheBar('specifiedper')
        } else if (estimatedcost === '') {
            offTheBar('estimatedcost')
        } else if (apr === '') {
            offTheBar('apr')
        } else if (mcavalue === 'Buyout' && fndname === '') {
            offTheBar('putfundername');
        } else if (mcavalue === 'Buyout' && payamt === '') {
            offTheBar('payoffamt');
        } else if (mcavalue === 'Buyout' && payds === '') {
            offTheBar('payoffdes');
        } else if (brrowersstate === 'Virginia' && recipient_name === '') {
            offTheBar('recipient_name');
        } else if (brrowersstate === 'Virginia' && recipient_address === '') {
            offTheBar('recipient_address');
        } else if (brrowersstate === 'Connecticut' && recipient_name === '') {
            offTheBar('recipient_name');
        } else if (brrowersstate === 'Connecticut' && recipient_address === '') {
            offTheBar('recipient_address');
        } else if (payment_type === '') {
            offTheBar('payment_type');
        } else if (org_description === '') {
            offTheBar('org_description');
        } else {
            if (brrowersstate == 'California') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_ca.php"
            }
            if (brrowersstate == 'Connecticut') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_con.php"
            }
            if (brrowersstate == 'Florida') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_fl.php"
            }
            if (brrowersstate == 'Georgia') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_gor.php"
            }
            if (brrowersstate == 'Kansas') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_kan.php"
            }
            if (brrowersstate == 'New York') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_ny.php"
            }
            if (brrowersstate == 'Utah') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_uth.php"
            }
            if (brrowersstate == 'Virginia') {
                stateurl = "https://app.readydisclosure.com/recordforpdf_vrg.php"
            }
            var obj = {

                brrowersstate: brrowersstate,
                mcavalue: mcavalue,
                grossfuncing: grossfuncing,
                estimatemonthly: estimatemonthly,
                dailypayment: dailypayment,
                bankstatement: bankstatement,
                orgfee: orgfee,
                payment_type: payment_type,
                brokerfee: brokerfee,
                broker_checkbox:broker_compensation_sts,
                estimatedterms: estimatedterms,
                financecharge: financecharge,
                totaldollercost: totaldollercost,
                netfunding: netfunding,
                estimatetotalpay: estimatetotalpay,
                paybackbal: paybackbal,
                specifiedper: specifiedper,
                estimatedcost: estimatedcost,
                org_description: org_description,
                luid: luid,
                apr: apr,
                fundername: fndname,
                payamt: payamt,
                paydescription: payds,
                recipient_address: recipient_address,
                recipient_name: recipient_name,
                business_name: business_name,
                businessowner_fname: businessowner_fname,
                businessowner_lname: businessowner_lname,
                businessowner_email: businessowner_email,
                merchant_pk: merchantid


            }
            console.log(obj)

            $.ajax({
                type: 'POST',
                url: stateurl,
                data: obj,
                cache: false,
                success: function (result) {
                    if (result !== 'fail') {
                        localStorage.setItem('pdflink', result)

                        if (planstsflds === 'Basic Pro' || planstsflds === 'Premium') {
                            opennoticepop();
                        } else {
                            $('#downpdf').css('display', 'inline-block');
                        }
                        calculateBalance();

                    } else {
                        alert('Cannot create pdf');
                    }
                },
            });
        }
    }

    function calculateBalance() {
        var newnal = disclosurebalence - 1
        localStorage.setItem('updisclo', newnal);
        localStorage.setItem('permission', 'on');

    }



    function shownotice(ms) {
        var element = document.getElementById(ms);
        element.classList.add("activenotice");
    }

    function offNotice(ms) {
        var element = document.getElementById(ms);
        element.classList.remove("activenotice");
    }

    function copyvalue(workid, showid) {
        var valshow = $('#' + showid).val();
        $('#'+workid).val(valshow)
        var formatvalue = formatMoney(valshow);
        $('#' + showid).val(formatvalue);
    }

    function showhideinputs(workid, showid) {
        $('#' + workid).css({ 'opacity': '1', 'z-index': '2' })
        $('#' + showid).css({ 'opacity': '0', 'z-index': '1' })
        var textField = document.getElementById(workid);
        textField.focus();

    }

    function gofordownlaod() {
        var linkval = localStorage.getItem('pdflink')
        $('#downpdf').css('display', 'none')
        window.open(linkval, '_blank');
    }

    function setExtrafield(mca) {

        var mcaval = $('#' + mca).val();
        if (mcaval === "Buyout") {
            $('#fundername').css('display', 'block');
            $('#payoff').css('display', 'block');
            $('#payofdes').css('display', 'block');
            $('#payoffdes').val('This payment is to satisfy payment to the funder.')
        } else if (mcaval === "Renewal") {
            $('#fundername').css('display', 'none');
            $('#putfundername').val('');
            $('#payoff').css('display', 'block');
            $('#payofdes').css('display', 'block');
            $('#payoffdes').val('This payment is to satisfy payment to the funder.')
        } else {
            $('#fundername').css('display', 'none');
            $('#putfundername').val('');
            $('#payoff').css('display', 'none');
            $('#payoffamt').val('');
            $('#payofdes').css('display', 'none');
            $('#payoffdes').val('');
        }
    }

    function openclientlist() {
        navigate('/clientlist');
    }

    function opennoticepop() {
        $('#alertpop').css('display', 'block');
        var msg = ' please notify your merchant the disclosure has been email.'
        $('#msg').text(msg);

    }


    function goforedit() {
        var uplan = localStorage.getItem('uplan');
        if (uplan === 'Basic Pro') {
            navigate('/editmerchant');
        } else if (uplan === 'Premium') {
            localStorage.setItem('targetpage','disclosure');
            navigate('/direct_premium_editmerchant');
        }
    }

    function setupborker_comp_status(sel_val){        
        broker_compensation_sts = sel_val;       
        if(sel_val==='yes'){
            $('#broker_fee').css('display','block')
        }else{
            $('#broker_fee').css('display','none')
        }
    }


    return (
        <div>
            <DashboardTopbar />
            <section className="contentarea">
                <div className='topprosection clientid nodisplay pb-50'>
                    <div className="smallcontainar">
                        <p id="marchid" className='quotetext'></p>
                        <button className='editbutton' onClick={goforedit}><i className='fa fa-edit'></i></button>
                    </div>
                </div>
                <div className='topprosection nodisplay proandprem'>
                    <div className='clientlistbtnholder'>
                        <button type="button" id="cllist" class="shortnextbtn" onClick={openclientlist}>Client List</button>
                    </div>
                    <div className='steppart'>
                        <div className="smallcontainar">
                            <div className="one-full"><h3>Initial Information</h3></div>
                        </div>
                        <div className="smallcontainar">
                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Business Name</label>
                                    <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not45') }} onMouseOut={() => {
                                        offNotice('not45')
                                    }}><i className="fas fa-info"></i></div>
                                    <p className="noticebox" id="not45">The name of the Business for which you will create this disclosure.</p>
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="business_name" name="business_name" placeholder="Business Name" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>First Name</label>
                                    <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not46') }} onMouseOut={() => {
                                        offNotice('not46')
                                    }}><i className="fas fa-info"></i></div>
                                    <p className="noticebox" id="not46">The first name of the Business owner for which you will create this disclosure.</p>
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="businessowner_fname" name="businessowner_fname" placeholder="First Name" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Last Name</label>
                                    <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not47') }} onMouseOut={() => {
                                        offNotice('not47')
                                    }}><i className="fas fa-info"></i></div>
                                    <p className="noticebox" id="not47">The last name of the Business owner for which you will create this disclosure.</p>
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="businessowner_lname" name="businessowner_lname" placeholder="Last Name" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Email</label>
                                    <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not48') }} onMouseOut={() => {
                                        offNotice('not48')
                                    }}><i className="fas fa-info"></i></div>
                                    <p className="noticebox" id="not48">The email of the Business owner for which you will create this disclosure.</p>
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="businessowner_email" name="businessowner_email" placeholder="Email" />

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="formholder">
                    <form method="post" action="">
                        <div className="formpart">
                            <div className='steppart'>
                                <div className='stepnumber'>
                                    <div className="smallcontainar">
                                        <div className="one-full"><h3>Step-1</h3></div>
                                    </div>
                                </div>
                                <div className="smallcontainar">
                                    <div className="formfieldholder newlabel">
                                        <div className="one-half">
                                            <label>State</label>
                                        </div>
                                        <div className="one-half">
                                            <select id="brrowersstate" name="brrowersstate" required onChange={() => { showhidesplfld('brrowersstate') }}>
                                                <option value="">Select State</option>
                                                <option value="California">California</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="New York">New York</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Virginia">Virginia</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="formfieldholder newlabel extrafield" id="recipentname">
                                        <div className="one-half pos-rel">
                                            <label>Recipient’s Name</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not25') }} onMouseOut={() => { offNotice('not25') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not25">The name of the Recipient who will receive the payoff amount</p>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input type="text" id="recipient_name" name="recipient_name" placeholder="Recipient’s Name" />

                                            </div>

                                        </div>
                                    </div>


                                    <div className="formfieldholder newlabel extrafield" id="recipentaddress">
                                        <div className="one-half pos-rel">
                                            <label>Recipient’s Address</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not26') }} onMouseOut={() => { offNotice('not26') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not26">The address of the Recipient who will receive the payoff amount</p>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input type="text" id="recipient_address" name="recipient_address" placeholder="Recipient’s Address" />

                                            </div>

                                        </div>
                                    </div>



                                    <div className="formfieldholder newlabel">
                                        <div className="one-half pos-rel">
                                            <label>Select MCA Type</label>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <select id="mcavalue" name="mcavalue" required onChange={() => { setExtrafield('mcavalue') }}>
                                                <option value="">Select MCA Type</option>
                                                <option value="MCA">MCA</option>
                                                <option value="Renewal">Renewal</option>
                                                <option value="Buyout">Buyout</option>

                                            </select>

                                        </div>
                                    </div>
                                    <div className="formfieldholder newlabel extrafield" id="fundername">
                                        <div className="one-half pos-rel">
                                            <label>Funder Name</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not22') }} onMouseOut={() => { offNotice('not22') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not22">The name of the funder who will receive the payoff amount</p>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input type="text" id="putfundername" name="putfundername" placeholder="Funder Name" />

                                            </div>

                                        </div>
                                    </div>


                                    <div className="formfieldholder newlabel extrafield" id="payoff">
                                        <div className="one-half pos-rel">
                                            <label>Payoff Amount</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not23') }} onMouseOut={() => { offNotice('not23') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not23">The amount of funds that will be paid to the funder</p>


                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel' type="hidden" id="payoffamt" name="payoffamt"  />

                                                <input type="text" id="coverpayoffamt" className='coverinput' onBlur={() => { calculateallvalues('payoffamt', 'coverpayoffamt') }} onClick={()=>{retdurntonumber('coverpayoffamt')}} placeholder="Payoff Amount"/>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel extrafield" id="payofdes">
                                        <div className="one-half pos-rel">
                                            <label>Payoff Description </label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not24') }} onMouseOut={() => { offNotice('not24') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not24">Please place any notes that will help facilitate the transaction</p>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input type="text" className='grayprefill' id="payoffdes" name="payoffdes" />

                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className='steppart'>
                                <div className='stepnumber'>
                                    <div className="smallcontainar">
                                        <div className="one-full"><h3 className='whiteheading'>Step-2</h3></div>
                                    </div>
                                </div>
                                <div className="smallcontainar">
                                    <div className="formfieldholder newlabel nodisplay" id="bank_statement">
                                        <div className="one-half pos-rel">
                                            <label>Number of Bank Statements Used</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not5') }} onMouseOut={() => { offNotice('not5') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not5">This is the amount of bank statements the underwriter used to provide a funding offer.</p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" id="bankstatement" name="bankstatement" className='winput' placeholder="Number of Bank Statements Used" onBlur={() => { calculateallvalues('noval', 'noval') }} />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="broker_comp">
                                        <div className="one-half">
                                            <label>Broker Compensation</label>
                                        </div>
                                        <div className="one-half">
                                            <div className='inputcoverhover'>
                                               <span className='pr-10 pull-left'> <input className='inputrel winput ' type="radio" id="broker_com_yes" name="brokercomp" onClick={()=>{setupborker_comp_status('yes')}} value = "Yes" />Yes</span>

                                               <span className='pr-10 pull-left'><input className='inputrel winput pr-10' type="radio" id="broker_com_no" name="brokercomp" onClick={()=>{setupborker_comp_status('no')}} value = "No" />No</span>                                                                    
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="broker_fee">
                                        <div className="one-half">
                                            <label>Broker Fee</label>
                                        </div>
                                        <div className="one-half">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel winput' type="hidden" id="brokerfee" name="brokerfee"  />

                                                <input type="text" id="coverbrokerfee" className='coverinput cinput'placeholder="Broker Fee" onBlur={() => { calculateallvalues('brokerfee', 'coverbrokerfee') }} onClick={()=>{retdurntonumber('coverbrokerfee')}}/>                                                                         
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="daily_payment">
                                        <div className='quoterow topthinborder'>
                                            <div className="one-half pos-rel">
                                                <label>Payment Type</label>
                                                <div className='eyeicon setbigmright' onMouseOver={() => { shownotice('not04') }} onMouseOut={() => { offNotice('not04') }}><i className="fas fa-info"></i></div>
                                                <p className="noticebox moreleft" id="not04">Here you will choose how frequently you want to pay.</p>
                                            </div>
                                            <div className="one-half">
                                                <div className='inputcoverhover'>
                                                    <select id="payment_type" onChange={() => { calculateallvalues('dailypayment', 'coverdailypayment') }}>
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Bi-Weekly">Bi-Weekly</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='quoterow bottomborder'>
                                            <div className="one-half pos-rel">
                                                <label>Payment Amount</label>
                                                <div className='eyeicon setbigmright' onMouseOver={() => { shownotice('not4') }} onMouseOut={() => { offNotice('not4') }}><i className="fas fa-info"></i></div>
                                                <p className="noticebox moreleft" id="not4">This is the Daily/Weekly/Bi-Weekly Payment merchant will make per contract.</p>
                                            </div>
                                            <div className="one-half">
                                                <div className='inputcoverhover'>
                                                    <input className='inputrel winput' type="hidden" id="dailypayment" name="dailypayment" />

                                                    <input type="text" id="coverdailypayment" className='coverinput cinput' placeholder="Payment Amount" onBlur={() => { calculateallvalues('dailypayment', 'coverdailypayment') }} onClick={()=>{retdurntonumber('coverdailypayment')}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="estmated_month_rev">
                                        <div className="one-half pos-rel">
                                            <label>Estimated Monthly Revenue</label>
                                            <div className='eyeicon shortright' onMouseOver={() => { shownotice('not3') }} onMouseOut={() => { offNotice('not3') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox moreleft" id="not3">This is a calulcation of an average monthly revnue off all bank statments used for funding offer.</p>
                                        </div>
                                        <div className="one-half">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel winput' type="hidden" id="estimatemonthly" name="estimatemonthly" />

                                                <input type="text" id="coverestimatemonth" className='coverinput cinput' placeholder="Estimated Monthly Revenue" onBlur={() => { calculateallvalues('estimatemonthly', 'coverestimatemonth') }} onClick={()=>{retdurntonumber('coverestimatemonth')}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="estimat_terms">
                                        <div className="one-half pos-rel">
                                            <label>Estimated Term</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not7') }} onMouseOut={() => { offNotice('not7') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not7">The estimated term refers to business days that would complete the contract with daily/payment set.</p>
                                        </div>
                                        <div className="one-half">
                                            <input className='inputrel winput' type="text" id="estimatedterms" name="estimatedterms" placeholder="Estimated Term" onBlur={() => { calculateallvalues('noval', 'noval') }} />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="gross_fund">
                                        <div className="one-half pos-rel">
                                            <label>Gross Funding</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not2') }} onMouseOut={() => { offNotice('not2') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not2">This is the Gross funds merchant will be recieve per Merchant Cash Advance contract.</p>

                                        </div>
                                        <div className="one-half pos-rel">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel winput' type="hidden" id="grossfuncing" name="grossfuncing" />

                                                <input type="text" id="covergross" className='coverinput cinput' placeholder="Gross Funding" onBlur={() => { calculateallvalues('grossfuncing', 'covergross') }} onClick={()=>{retdurntonumber('covergross')}} />
                                            </div>

                                        </div>
                                    </div>


                                    <div className="formfieldholder newlabel nodisplay" id="origin_fee">
                                        <div className="one-half pos-rel">
                                            <label >Origination Fee</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not6') }} onMouseOut={() => { offNotice('not6') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not6">The amount charged for originating the funding</p>
                                        </div>
                                        <div className="one-half">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel winput' type="hidden" id="orgfee" name="orgfee"  />
                                                <input type="text" id="coverorgfee" className='coverinput cinput' placeholder="Origination Fee" onBlur={() => { calculateallvalues('orgfee', 'coverorgfee') }} onClick={()=>{retdurntonumber('coverorgfee')}} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="all_other_fees">
                                        <div className="one-half pos-rel">
                                            <label >All Other Potential Fees</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not6') }} onMouseOut={() => { offNotice('not6a') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not6a">All Other Potential Fees and Charges NOT Included in the Finance Charge </p>
                                        </div>
                                        <div className="one-half">
                                            <div className='inputcoverhover'>
                                                <input className='inputrel winput' type="text" id="org_description" name="allotherfee" placeholder="All Other Potential Fees" />
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>


                            <div className='steppart'>
                                <div className='stepnumber'>
                                    <div className="smallcontainar">
                                        <div className="one-full"><h3 className='whiteheading'>Step-3</h3></div>
                                    </div>
                                </div>
                                <div className="smallcontainar">

                                    <div className="formfieldholder newlabel nodisplay" id="est_annul_percnt_rate">
                                        <div className="one-half pos-rel">
                                            <label>Estimated Annual Percentage Rate (APR)</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not15') }} onMouseOut={() => { offNotice('not15') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not15">Formula = ((Payback Balance - Net Funding)/Gross Funding)*365)/Term</p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="apr" name="apr" placeholder="Estimated Annual Percentage Rate (APR)" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="estim_month_cost">
                                        <div className="one-half pos-rel">
                                            <label>Estimated Monthly Cost</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not14') }} onMouseOut={() => { offNotice('not14') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not14">Formula =  (Daily Payment) x 21/(Weekly Payment) x 4/(Bi-weekly Payment) x 2 </p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="estimatedcost" name="estimatedcost" placeholder="Estimated Monthly Cost" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="estim_total_pay_amt">
                                        <div className="one-half pos-rel">
                                            <label>Estimated Total Payment Amount</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not11') }} onMouseOut={() => { offNotice('not11') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not11">Formula = Estimated Terms * Payment Amount;</p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="estimatetotalpay" name="estimatetotalpay" placeholder="Estimated Total Payment Amount" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="fin_charg">
                                        <div className="one-half pos-rel">
                                            <label>Finance Charge</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not8') }} onMouseOut={() => { offNotice('not8') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not8">Formula = (Estimated Term x Daily Payment) - Gross Funding </p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="financecharge" name="financecharge" placeholder="Finance Charge" readOnly />
                                            <input type="hidden" className='cinput'/>
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="net_fnd">
                                        <div className="one-half pos-rel">
                                            <label>Net Funding</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not10') }} onMouseOut={() => { offNotice('not10') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not10">Formula = Gross Funding - (Origination Fee + Payoff + Buyout) </p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="netfunding" name="netfunding" placeholder="Net Funding" />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="pay_bk_bal">
                                        <div className="one-half pos-rel">
                                            <label>Payback Balance</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not12') }} onMouseOut={() => { offNotice('not12') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not12">Formula = Estimated Term x Daily Payment</p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="paybackbal" name="paybackbal" placeholder="Payback Balance" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>

                                    <div className="formfieldholder newlabel nodisplay" id="spec_percent">
                                        <div className="one-half pos-rel">
                                            <label>Specified Percentage</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not13') }} onMouseOut={() => { offNotice('not13') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not13">Formula = (Daily Payment  x 21) / (Monthly Revenue)</p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="specifiedper" name="specifiedper" placeholder="Specified Percentage" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>


                                    <div className="formfieldholder newlabel nodisplay" id="tot_dol_cos">
                                        <div className="one-half pos-rel">
                                            <label>Total Dollar Cost</label>
                                            <div className='eyeicon setbigright' onMouseOver={() => { shownotice('not9') }} onMouseOut={() => { offNotice('not9') }}><i className="fas fa-info"></i></div>
                                            <p className="noticebox" id="not9">Formula = (Estimated Term x Daily Payment) - Gross Funding </p>
                                        </div>
                                        <div className="one-half">
                                            <input type="text" className='winput' id="totaldollercost" name="totaldollercost" placeholder="Total Dollar Cost" readOnly />
                                            <input type="hidden" className='cinput' />
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="nextbtndiv pt-60 pb-200">


                                <button type="button" id="generate" className="shortnextbtn" onClick={previewall}>Generate</button>

                                <button type="button" id="downpdf" className="downlaodbtn" onClick={gofordownlaod}><i className="fas fa-download"></i></button>

                                <button type="button" id="sendbtn" className="downlaodbtn" onClick={opennoticepop}>Send</button>

                            </div>


                        </div>

                    </form>
                </div>
                <section className='poptopay' id="previewpop">
                    <div className='previewarea'>
                        <h3 className='previewheading'>Please check and confirm all values are correct</h3>
                        <div className='inforow'>
                            <div className='one-half nopadding'>
                                <p className='text_bold'>State</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="brrowersstateprev"></p>
                            </div>
                        </div>

                        <div className='inforow receipen_name nodisplay' >
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Recipient’s Name</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="recipient_nameprev"></p>
                            </div>
                        </div>

                        <div className='inforow recipient_address nodisplay' >
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Recipient’s Address</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="recipient_addressprev"></p>
                            </div>
                        </div>

                        <div className='inforow'>
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Selected MCA Type</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="mcavalueprev"></p>
                            </div>
                        </div>

                        <div className='inforow funder_name nodisplay'>
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Funder Name</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="putfundernameprev"></p>
                            </div>
                        </div>

                        <div className='inforow payoff_amt nodisplay'>
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Payoff Amount</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="payoffamtprev"></p>
                            </div>
                        </div>

                        <div className='inforow payoff_desc nodisplay'>
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Payoff Description</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="payoffdesprev"></p>
                            </div>
                        </div>



                        <div className='inforow' id="gross_fund_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Gross Funding</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="covergrossprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="estmated_month_rev_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Estimated Monthly Revenue</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="coverestimatemonthprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="daily_payment_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Payment</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="coverdailypaymentprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="bank_statement_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Bank Statements Used</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="bankstatementprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="origin_fee_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Origination Fee</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="coverorgfeeprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="broker_fee_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Broker Fee</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="coverbrokerfeeprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="estimat_terms_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Estimated Term</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="estimatedtermsprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="fin_charg_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Finance Charge</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="financechargeprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="tot_dol_cos_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Total Dollar Cost</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="totaldollercostprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="net_fnd_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Net Funding</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="netfundingprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="estim_total_pay_amt_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Estimated Total Payment Amount</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="estimatetotalpayprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="pay_bk_bal_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Payback Balance</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="paybackbalprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="spec_percent_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Specified Percentage</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="specifiedperprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="estim_month_cost_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Estimated Monthly Cost</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="estimatedcostprev"></p>
                            </div>
                        </div>

                        <div className='inforow' id="est_annul_percnt_rate_prev">
                            <div className='one-half nopadding'>
                                <p className='text_bold'>Estimated Annual Percentage Rate (APR)</p>
                            </div>
                            <div className='one-half nopadding'>
                                <p id="aprprev"></p>
                            </div>
                        </div>

                        <div className="nextbtndiv pt-60 pb-200">
                            <button type="button" id="conf" className="shortnextbtn" onClick={sendvalues}>Confirm</button>
                            <button type="button" id="preview" className="shortnextbtn" onClick={closepop}>Close</button>
                        </div>


                    </div>
                </section>

            </section>
            <section class="poptopay" id="alertpop">
                <div className='shortwhitebase'>
                    <h2>Alert</h2>
                    <p id="msg"></p>
                    <div className='buttonholder'>
                        <button onClick={openclientlist}>Okay</button>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}


