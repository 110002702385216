import React from 'react'
import { useEffect,useState } from 'react'
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';

export default function Disclosurelist() {
    const [luid, setluid] = useState(localStorage.getItem('uid'))
    const [clientlistarr, setClientList] = useState([]); 
    var uname =  localStorage.getItem('selusername');
    
    var testarr = [];
    useEffect(() => {
        const laodListRecord = () => {
            var merchant_pk =  localStorage.getItem('marchidkey');
            var uplan = localStorage.getItem('uplan');
            const obj = { LUID: luid,merchant_pk:merchant_pk,profile_type:uplan }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/getdisclouserlist.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result !== null) {
                        testarr = result;
                        var len = testarr.length
                        if(len>0){
                            setClientList(result);
                        }else {
                            $('#noticebox').css('display','block')
                            $('#noticetext').text('You have not create any disclosure for this client. Go back to list page and create a disclosure.');
                        }
                        
                    } else {
                        $('#noticebox').css('display','block')
                        $('#noticetext').text('You have not create any disclosure for this client. Go back to list page and create a disclosure.');
                    }
                },
            });
        };
    
        const timer = setTimeout(laodListRecord, 1000);
        return () => clearTimeout(timer);
    }, [luid])


    function gotothelink(linkurl){
        var pdflink = "https://app.readydisclosure.com/generatepdf_preview.php?jsonpdf="+linkurl
        window.open(pdflink, '_blank');
    }

    function goforcancel(doc_id,luid,pr_key,sig_merid,sig_pk){
        var cancellink = "https://app.readydisclosure.com/generatepdf_cancel.php?doc_id="+doc_id+"&pri_key="+pr_key+"&LUID="+luid+"&sign_merid="+sig_merid+"&sign_pk="+sig_pk
        //window.open(pdflink, '_blank');
        window.location.href=cancellink;
    }

    function goforDownlaod(doc_id,luid,pr_key,sig_merid,sig_pk){
        var downlink = "https://app.readydisclosure.com/generatepdf_certificate.php?doc_id="+doc_id+"&pri_key="+pr_key+"&LUID="+luid+"&sign_merid="+sig_merid+"&sign_pk="+sig_pk;
        window.open(downlink, '_blank');
    }

    const renderClientList = (clientlistarr, index) => {
        const { created_at, pdf_link,document_id,LUID,primary_key,signed_merId,signed_pk,status,contract } = clientlistarr;

        $('#noticebox').css('display','none')

       const handlergotothelink = ()=>{
        gotothelink(pdf_link)
       }

       const handlergoforcancel = ()=>{
        goforcancel(document_id,LUID,primary_key,signed_merId,signed_pk);
       }

       const handlergofordown = ()=>{
        goforDownlaod(document_id,LUID,primary_key,signed_merId,signed_pk);
       }
       setTimeout(resetobject,1000);
       function resetobject(){
        if(pdf_link==="test"||pdf_link===''){
            $('#linkpart i').css('display','none');
            $('#linkpart').text('Not Signed Yet');
            $('#downloadcell').css('display','none');
            $('#democell').css('display','block');
            $('#contract').css('display','none');
            $('#denocontract').css('display','block')
           }else{
            $('#trashcell').css('display','none');
            $('#downloadcell').css('display','block');
            $('#democell').css('display','none');
            $('#contract').css('display','block');
            $('#denocontract').css('display','none')
           }

           if(document_id===''||document_id===null){
                $('#docid').text('---');
                $('#trashcell').css('display','none');
           }
       }      

        return (
            <div
                key={index}
                id={`content${index}`}
                className='one-full mtb-5'
            >
                <div className='inforow pb-26 bb-1'>
                
                    <div className='one-fifth-smlarge pos-rel'><span className='namespan linkspan' >{uname}</span></div>
                    <div className='one-fifth'>{created_at}</div> 
                    <div className='one-fifth_extrasmall_19' id="docid">{document_id}</div> 
                    <div className='one-fifth ' onClick={handlergotothelink} id="linkpart"><i className='far fa-eye'  title="View Disclosure"></i> Preview</div>
                    <div className='one-fifth' id="contract">{contract}</div>
                    <div className='one-fifth nodisplay' id="denocontract">---</div>
                    <div className='one-fifth_small' id="downloadcell" onClick={handlergofordown} ><i className='fa fa-eye' title="View Certificate"></i>View</div>
                    <div className='one-fifth_small nodisplay' id="democell">---</div>
                    <div className='one-fifth greenlabel'>{status}</div> 
                    <div className='one-fifth_small' id="trashcell"><i className='fa fa-trash' onClick={handlergoforcancel} title="View Disclosure"></i></div>
                    
                </div>
            </div>
        );

    };
   
  return (
    <div>

        <DashboardTopbar />

        <div className='reg_container'>
                <div className='reg_row'>
                    <div className='one-full mtb-5'>
                        
                        <div className='inforow coverrow'>
                           
                            <div className='one-fifth-smlarge'>Merchant Name</div>
                            <div className='one-fifth'>Created On </div>
                            <div className='one-fifth_extrasmall_19'>Document ID</div>
                            <div className='one-fifth '>Disclosure</div>
                            <div className='one-fifth '>Contract</div>
                            <div className='one-fifth_small'>Certificate</div>
                            
                            <div className='one-fifth greenlabel'>Status</div>
                            <div className='one-fifth_small'>Remove</div>
                            
                        </div>
                    </div>
                </div>

                <div className='reg_row' id="clientlistarea">
                    {clientlistarr.map((clientlistarr, index) => renderClientList(clientlistarr, index))}
                </div>
                <div className='reg_row nodisplay' id='noticebox'>
                    <h2 class="alerttext" id="noticetext"></h2>
                </div>
            </div>
          
        <Footer />      
    </div>
  )
}
