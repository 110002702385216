import React from 'react'
import Toppart from '../Component/toppart';
import Signin_Topbar from './Signin_Topbar';
import $ from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import Footer from './Footer';

export default function Forgot_pass() {
    const navigate = useNavigate();
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }

    function sendlink(){
        var uemail = $('#useremail').val();
       if(uemail===''){
        alert('Please insert your registered email.');
       }else{

        if(validateEmail(uemail)){
            localStorage.setItem('uemail',uemail);
            var obj = {
                useremail:uemail,
                
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/resetpass.php',
                data: obj,
                cache: false,
                success: function(result) { 
                   if(result!='fail'){   
                    localStorage.setItem('uid',result);
                   navigate('/requestconf');
                   }else{
                      alert('Please check your email.'); 
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
       }
    }
  return (
    <div>
      <Toppart/>
         <Signin_Topbar />
         <section className='mh-adj'>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont'>
                   <h3 class="formheading">Password Reset</h3>
                <p className='infotext'>Enter the email address you used to sign up.</p>
                    <div className='loginform'>
                        <input type="email" name="useremail" id="useremail" placeholder="name@domain.com" />
                    </div>
                    
                    <div className='loginform'>
                        <button onClick={sendlink}>SUBMIT</button>
                    </div>
                    </div>
                    
                    <div className='bottomredirection'>
                      
                    </div> 
                </div>  
            </div>
        
    </section>
            <Footer />
    </div>
  )
}
