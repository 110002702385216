import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import Footer from './Footer';
import { useEffect } from 'react';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';

export default function Direct_Editmerchant() {

    const navigate = useNavigate();
    useEffect(()=>{
        var newmarid = localStorage.getItem('marchantid');
        $('#marid').val(newmarid);

        var obj = {
            dcId_PK:newmarid
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/getidformerchant.php',
            data: obj,
            cache: false,
            success: function (result) {
                if (result !== 'fail') {
                    var arr = result.split('|');
                    $('#business_name').val(arr[0]);
                    $('#business_dba').val(arr[1]);
                    $('#marchent_fname').val(arr[2]);
                    $('#marchent_lname').val(arr[3]);
                    $('#business_email').val(arr[4]);             
                } else {
                    alert('We cannot find any record.');
                }
            },
        });
    })

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function editmarchent(){
        var business_name = $('#business_name').val();
        var business_dba = $('#business_dba').val();
        var marchent_fname = $('#marchent_fname').val();
        var marchent_lname = $('#marchent_lname').val();
        var business_email = $('#business_email').val();
        var luid = localStorage.getItem('uid');
        var marchid = localStorage.getItem('marchid');
        if(business_name===''){
            offTheBar('business_name')
        }else if(marchent_fname===''){
            offTheBar('marchent_fname')
        }else if(business_dba===''){
            offTheBar('business_dba')
        }else if(marchent_lname===''){
            offTheBar('marchent_lname')
        }else if(business_email===''){
            offTheBar('business_email')
        }else{
            var obj = {
                business_name:business_name,
                business_dba:business_dba,
                marchent_fname:marchent_fname,
                marchent_lname:marchent_lname,
                business_email:business_email,
                luid:luid,
                dcId_PK:marchid
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/edit_merchant_record.php',
                data: obj,
                cache: false,
                success: function(result) { 
                   if(result.trim()!=="fail"){   
                        navigate('/clientlist');
                    }else{
                        alert('Plese check your email.'); 
                    }
                },
                });
        }
    }
  return (
    <div>
      <DashboardTopbar />
      <div className="smallcontainar">                      
            <div className='fullwidth ptb-20 backlink'>
                <Link to="/clientlist">Back to List</Link>
            </div>

                        <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Business Name</label>
                                   
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="business_name" name="business_name" placeholder="Business Name" />

                                    </div>

                                </div>
                            </div>
                           
                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Business DBA</label>
                                   
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="business_dba" name="business_dba" placeholder="Business DBA" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Merchant First Name</label>
                                   
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="marchent_fname" name="marchent_fname" placeholder="Merchant First Name" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Merchant Last Name</label>
                                   
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="marchent_lname" name="marchent_lname" placeholder="Merchant Last Name" />

                                    </div>

                                </div>
                            </div>

                            <div className="formfieldholder newlabel">
                                <div className="one-half pos-rel">
                                    <label>Email Address</label>
                                   
                                </div>
                                <div className="one-half pos-rel">
                                    <div className='inputcoverhover'>
                                        <input type="text" id="business_email" name="business_email" placeholder="Email Address" />

                                    </div>

                                </div>
                            </div>

                            <div className="nextbtndiv pt-60 pb-200">
                        <button id="ntbtn" className="nextbtn" onClick={editmarchent}>Update<i
                                className="fas fa-caret-right"></i></button>
                        
                    </div>
                    </div>
                    <Footer />
    </div>
  )
}
