import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import Cardimg from '../images/cards.jpg';
import $ from 'jquery';
import Footer from './Footer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Update_billing_info() {
    var basicpricelist = [

        {
            "qty": "1",
            "price": "8",
            "display": "$8"
        },
        {
            "qty": "5",
            "price": "39",
            "display": "$39"
        },
        {
            "qty": "25",
            "price": "187",
            "display": "$187"
        },
        {
            "qty": "50",
            "price": "360",
            "display": "$360"
        },
        {
            "qty": "100",
            "price": "700",
            "display": "$700"
        }
    ]

    var automate = [
        {
            "qty": "1",
            "price": "11",
            "display": "$11"
        },
        {
            "qty": "5",
            "price": "54",
            "display": "$54"
        },
        {
            "qty": "25",
            "price": "256",
            "display": "$256"
        },
        {
            "qty": "50",
            "price": "495",
            "display": "$495"
        },
        {
            "qty": "100",
            "price": "950",
            "display": "$950"
        }
    ]

    var basicpro = [
        {
            "qty": "1",
            "price": "9",
            "display": "$9"
        },
        {
            "qty": "5",
            "price": "44",
            "display": "$44"
        },
        {
            "qty": "25",
            "price": "212",
            "display": "$212"
        },
        {
            "qty": "50",
            "price": "410",
            "display": "$410"
        },
        {
            "qty": "100",
            "price": "800",
            "display": "$800"
        }
    ]
    var balancequn = 0;
    var selectplan = '';
    var selectedplan = '';
    var permission = '';
    var subscpriptionprice = 0;
    var packageprice = 0;
    var packagequantity = 0;
    var totalprice = 0;
    var subsdiscountpercent = 0;
    var subsdiscountcode = '';
    var subsdiscountsts = 'false';

    var disclosercodests = 'false';
    var discloserpercent = 0;
    var disclosercode = '';
    var currentplan = '';
    var currentsubs = 0;
    var cvvvalue = '';

    var orgsubcriptionprice = 0;
    var orgdisclosureprice = 0;


    

    useEffect(() => {
        balancequn = parseInt(localStorage.getItem('updisclo'));
        permission = localStorage.getItem('permission');
        var extraqn = parseInt(localStorage.getItem('extraval'));
        var newbal = balancequn + extraqn;




        var luid = localStorage.getItem('uid');
        $('#luid').val(luid);



        var obj = {
            LUID: luid,
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/update_billinginfo.php',
            data: obj,
            cache: false,
            success: function (result) {

                if (result !== 'fail') {

                    //4242|03|27|Jun 09, 2024
                    var arr = result.split('|');
                    var crdnumber = arr[0];
                    var crdexpmonth = arr[1];
                    var crdexpyear = arr[2];
                    var nextpaydt = arr[3];
                    var mextpayamt = arr[13];
                    cvvvalue = arr[14];
                    var cardfullnumber = arr[5];
                    var carholdername = arr[6];
                    selectedplan = arr[7];
                    currentplan = selectedplan;
                    currentsubs = mextpayamt;
                    var valopt = crdnumber + '|' + crdexpmonth + '|' + crdexpyear;
                    var optionval = '<option value="' + valopt + '">Visa ending with xxxxxxxxxxxx' + crdnumber + ' Expire on ' + crdexpmonth + '/' + crdexpyear + '</option>';
                    $('#disqun').append(optionval);
                    $('.nxtpaydt').text('Your next payment of ' + selectedplan + ' is due on ' + nextpaydt);
                    $('.monprice').text(mextpayamt);
                    $('.nextpay').text('You have to pay ' + mextpayamt)
                    $('#cardexpmonth').val(crdexpmonth);
                    $('#cardholdername').val(carholdername)
                    $('#cardnumber').val(cardfullnumber)
                    $('#cardexpyear').val(crdexpyear)
                    $('#balanceamt').text('Number of disclosure you have remaining: ' + arr[8]);
                    $('#planname').text(selectedplan);
                    $('#planprice').text("Current Plan");



                    $('#currentplan').text('Your Current Plan is ' + selectedplan);
                    if (selectedplan == 'Basic') {
                        $("#basicpay").prop("checked", true);
                        $('#basicradio').css('display', 'none')
                        setBasicEdit();
                    }
                    if (selectedplan == 'Premium') {
                        $("#autopay").prop("checked", true);
                        $('#preradio').css('display', 'none')
                        setAutomatedEdit();
                    }
                    if (selectedplan == 'Basic Pro') {
                        $("#basicpro").prop("checked", true);
                        $('#proradio').css('display', 'none')
                        setBasicProEdit();
                    }
                    var addressval = arr[9];
                    var cityval = arr[10];
                    var stateval = arr[11];
                    var zipval = arr[12];
                    $('#address').val(addressval);
                    $('#cardholdname').val(carholdername);
                    $('#cardnumberedit').val(cardfullnumber);
                    document.getElementById('expmonth').value = crdexpmonth;
                    document.getElementById('expyear').value = crdexpyear;
                    $('#city').val(cityval);
                    $('#zipad').val(zipval);
                    document.getElementById('statead').value = stateval;



                } else {
                    alert('Plese check your email.');
                }
            },
        });

    })


    function applysubscriptioncode() {
        subsdiscountcode = $('#subscribecode').val();
        var luid = localStorage.getItem('uid')
        if (subsdiscountcode === '') {
            alert('You have not entered any code.')
        } else {
            var obj = {
                LUID: luid,
                discountcode: subsdiscountcode

            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/check_discount_subscribe_code.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result.trim() !== 'fail') {
                        subsdiscountpercent = result;
                        subsdiscountsts = 'true'
                        alert('Discount coupon for new plan has been applied successfully')

                    } else {
                        subsdiscountsts = 'false';
                        subsdiscountpercent = 0;
                        $('#plandiscount').text('Not Applied')
                        alert('Your code is not valid.');
                    }
                },
            });
        }
    }


    function calculatediscount() {
        if (subsdiscountpercent > 0) {
            var discount = parseInt(subsdiscountpercent);
            var discountamt = (discount / 100) * subscpriptionprice;
            subscpriptionprice = subscpriptionprice - discountamt;
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice);
            totalprice = subscpriptionprice + packageprice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
            $('#plandiscount').text('Applied')
        } else {
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice);
            totalprice = subscpriptionprice + packageprice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
        }
    }




    function applydisclosurecode() {
        disclosercode = $('#disclosercodevalue').val();
        var luid = localStorage.getItem('uid')
        var pquinty = $('#plandetails').val();
        var arr = pquinty.split('|');
        var quantity = arr[0];
        if (disclosercode === '') {
            alert('You have not entered any code.')
        } else {
            var obj = {
                LUID: luid,
                discountcode: disclosercode,
                quantity:quantity

            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/check_discount_discloser_code.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result.trim() !== '0') {
                        discloserpercent = result;
                        disclosercodests = 'true';
                        alert('Discount coupon for disclosure has been applied successfully')

                    } else {
                        disclosercodests = 'false';
                        discloserpercent = 0;
                        alert('Your code is not valid for this package.');
                    }
                },
            });
        }
    }

    function calculatediscountdisclos() {
        if (discloserpercent > 0) {
            var discount = parseInt(discloserpercent);
            var discountamt = (discount / 100) * packageprice;
            packageprice = packageprice - discountamt;
            var printdiscloseprice = formatMoney(packageprice);
            $('#valtodisplay').text(printdiscloseprice);
            totalprice = subscpriptionprice + packageprice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
            $('#disclosurediscount').text('Applied')
        } else {
            var printdiscloseprice = formatMoney(packageprice);
            $('#valtodisplay').text(printdiscloseprice);
            totalprice = subscpriptionprice + packageprice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
        }
    }




    function updateplantext() {
        $('#planname').text(selectedplan);
        $('#planprice').text('$' + subscpriptionprice);
        totalprice = parseInt(subscpriptionprice + packageprice);
        $('#totalprice').text('$' + totalprice);
        $('#savedatabtn').css('display', 'block')
        calculatediscount();
        // $('#cvvblock').css('display', 'block')
    }
    function updatedisclosure() {
        var quinty = $('#plandetails').val();
        var arr = quinty.split('|');
        packagequantity = arr[0];
        localStorage.setItem('extraval', packagequantity);
        packageprice = parseInt(arr[1]);
        orgdisclosureprice = packageprice;
        $('#quanprint').text(packagequantity);
        $('#valtodisplay').text('$' + packageprice);
        $('#savedatabtn').css('display', 'block')
        // $('#cvvblock').css('display', 'block')
        totalprice = parseInt(subscpriptionprice + packageprice);
        $('#totalprice').text('$' + totalprice);
        calculatediscountdisclos();

    }
    function showUpdate() {
        $('#updatediscloserbtn').css('display', 'block');
    }
    function resetCalculation() {
        $('#totalprice').text('$0.00');
        $('#savedatabtn').css('display', 'none')
        $('#updateplanbtn').css('display', 'none');
        $('#cvvblock').css('display', 'none');
        $('#quanprint').text('0');
        $('#valtodisplay').text('$00.00');
        packagequantity = 0;
        packageprice = 0;
        subscpriptionprice = 0;
        orgsubcriptionprice = 0;
        orgdisclosureprice = 0;
        $('#updatediscloserbtn').css('display', 'none');
        $('#planname').text(currentplan);
        selectedplan = currentplan;
        $('#planprice').text("Current Plan");
        $('input[name="basicpay"]').prop('checked', false);
        if (selectedplan == 'Basic') {
            $("#basicpay").prop("checked", false);
            setBasicEdit();
        }
        if (selectedplan == 'Premium') {
            $("#autopay").prop("checked", false);
            setAutomatedEdit();
        }
        if (selectedplan == 'Basic Pro') {
            $("#basicpro").prop("checked", false);
            setBasicProEdit();
        }
    }
    function selectradio(radiobt) {

        $('#updateplanbtn').css('display', 'block');
        $("#" + radiobt).prop("checked", true);
        if (radiobt == 'basicpay') {
            selectedplan = "Basic";
            subscpriptionprice = 99.99;
            orgsubcriptionprice = subscpriptionprice;
            setBasicEdit();

        }
        if (radiobt == 'autopay') {
            selectedplan = "Premium";
            subscpriptionprice = 159.99;
            orgsubcriptionprice = subscpriptionprice;
            setAutomatedEdit();
        }
        if (radiobt == 'basicpro') {
            selectedplan = "Basic Pro";
            subscpriptionprice = 129.99;
            orgsubcriptionprice = subscpriptionprice;
            setBasicProEdit();
        }

    }



    function setBasicEdit() {
        $('#plandetails').html('');

        var len = basicpricelist.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = basicpricelist[i].display + ' For ' + basicpricelist[i].qty;
            var value = basicpricelist[i].qty + '|' + basicpricelist[i].price + '|' + basicpricelist[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#plandetails').append(htm);
        }

    }

    function setAutomatedEdit() {
        $('#plandetails').html('');

        var len = automate.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = automate[i].display + ' For ' + automate[i].qty;
            var value = automate[i].qty + '|' + automate[i].price + '|' + automate[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#plandetails').append(htm);
        }

    }

    function setBasicProEdit() {
        $('#plandetails').html('');

        var len = basicpro.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = basicpro[i].display + ' For ' + basicpro[i].qty;
            var value = basicpro[i].qty + '|' + basicpro[i].price + '|' + basicpro[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#plandetails').append(htm);
        }

    }

    function shownotice(ms) {
        var element = document.getElementById(ms);
        element.classList.add("activenotice");
    }

    function offNotice(ms) {
        var element = document.getElementById(ms);
        element.classList.remove("activenotice");
    }

    function openEditArea() {
        $('#editcard').css('display', 'block');
        $('#closeedit').css('display', 'block')
    }
    function closeeditpart() {
        $('#editcard').css('display', 'none');
        $('#closeedit').css('display', 'none')
    }




    function sendForsave() {

        var luid = $('#luid').val();
        var cardholdername = $('#cardholdername').val();
        var cardnumber = $('#cardnumber').val();
        var cardexpmonth = $('#cardexpmonth').val();
        var cardexpyear = $('#cardexpyear').val();

        var obj = {
            packagequantity: packagequantity,
            packageprice: packageprice,
            disclosurecoupon: disclosercode,
            plancoupon: subsdiscountcode,
            selectedplan: selectedplan,
            LUID: luid,
            cardholdername: cardholdername,
            cardnumber: cardnumber,
            cardexpmonth: cardexpmonth,
            cardexpyear: cardexpyear,
            cvvnumber: cvvvalue,
            subscpriptionprice: subscpriptionprice,
            discloserpercent: discloserpercent,
            subsdiscountpercent: subsdiscountpercent,
            orgdisclosureprice: orgdisclosureprice,
            orgsubcriptionprice: orgsubcriptionprice
        }
        var alldataarr = [];
        alldataarr.push(obj);
        let arrayString = JSON.stringify(alldataarr);
        localStorage.setItem('dataarray', arrayString);
        window.location.href = 'https://app.readydisclosure.com/confirempackageandplan.php';

    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    function clearformcard() {
        $('#cardholdname').val('');
        $('#cardnumberedit').val('');
        $('#expmonth').val('');
        $('#expyear').val('24');
        $('#cvv').val('');
        $('#address').val('');
        $('#city').val('');
        $('#statead').val('');
        $('#zipad').val('');
    }

    function updateCardInfo() {
        var luid = localStorage.getItem('uid');
        var cardholdname = $('#cardholdname').val();
        var cardnumberedit = $('#cardnumberedit').val();
        var expmonth = $('#expmonth').val();
        var expyear = $('#expyear').val();
        var cvv = $('#cvv').val();
        var address = $('#address').val();
        var city = $('#city').val();
        var statead = $('#statead').val();
        var zipad = $('#zipad').val();
        if (cardholdname == '') {
            offTheBar('cardholdname')
        } else if (cardnumberedit == '') {
            offTheBar('cardnumberedit')
        } else if (address == '') {
            offTheBar('address')
        } else if (statead == '') {
            offTheBar('statead')
        } else if (zipad == '') {
            offTheBar('zipad')
        } else if (expmonth == '') {
            offTheBar('expmonth')
        } else if (expyear == '') {
            offTheBar('expyear')
        } else if (city == '') {
            offTheBar('city')
        } else if (cvv == '') {
            offTheBar('cvv')
        } else {
            var obj = {
                cardholdname: cardholdname,
                cardnumberedit: cardnumberedit,
                expmonth: expmonth,
                expyear: expyear,
                cvv: cvv,
                address: address,
                city: city,
                statead: statead,
                zipad: zipad,
                LUID: luid
            }

            var alldataarr = [];
            alldataarr.push(obj);
            let arrayString = JSON.stringify(alldataarr);
            localStorage.setItem('dataarray', arrayString);
            window.location.href = 'https://app.readydisclosure.com/confirmupdatecard.php';

        }

    }

    function showhidesubsopt() {
        var checkbox = document.getElementById('subscriptiontoken');
        if (checkbox.checked) {
            $('#substoken').css('display', 'block')
        } else {
            $('#substoken').css('display', 'none')
        }
    }

    function showdisclosertoken() {
        var checkbox = document.getElementById('disclostoken');
        if (checkbox.checked) {
            $('#disclosuretoken').css('display', 'block')
        } else {
            $('#disclosuretoken').css('display', 'none')
        }
    }


    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return 'Invalid number';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }
    return (
        <div>
            <DashboardTopbar />
            <section className="contentarea mb-60">



                <div className='container'>
                    <div className="toprow">
                        <div className="bigbox">
                            <button className="srchbtn" ><Link to="/updateprofile">Profile</Link></button>
                            <button className="srchbtn bluebtn" >Billing</button>
                            <button className="srchbtn"  ><Link to="/invoicelisting">Invoices</Link></button>
                        </div>
                        <div className="bigbox">
                            <div className="one-full pos-rel">

                                <div className='eyeicon toright setbigright' onMouseOver={() => { shownotice('not9') }} onMouseOut={() => { offNotice('not9') }}><i className="fas fa-info"></i></div>
                                <p className="noticeboxrdight" id="not9">This button will reset all fields as before. By clicking on this button you can remove all items you choose here.</p>
                                <button class="resetbtn" onClick={resetCalculation}>Reset</button>
                            </div>
                        </div>

                    </div>
                    <div className='reg_row'>
                        <div className='two-third pr-40'>
                            <div className='row division nopadding'>
                                <div className='smallheadingbar nopadding nomargin'>
                                    <h3 id="currentplan" className='nopadding nomargin'>Current Plan</h3>
                                </div>
                            </div>
                            <div className='fullwidth changeplan division'>
                                <div className='fullwidth pt-10' id="basicradio">
                                    <span class="pos-rel">
                                        <button className='radiocoverbutton' onClick={() => { selectradio('basicpay') }}>&nbsp;</button>
                                        <input type="radio" id="basicpay" name="basicpay" value="basic" />
                                        <p>Basic - $99.99</p>
                                    </span>
                                </div>

                                <div className='fullwidth pt-10' id="proradio">
                                    <span class="pos-rel">
                                        <button className='radiocoverbutton' onClick={() => { selectradio('basicpro') }}>&nbsp;</button>
                                        <input type="radio" id="basicpro" name="basicpay" checked value="Automated" />
                                        <p> Pro - $129.99</p>
                                    </span>
                                </div>

                                <div className='fullwidth pt-10' id="preradio">
                                    <span class="pos-rel">
                                        <button className='radiocoverbutton' onClick={() => { selectradio('autopay') }}>&nbsp;</button>
                                        <input type="radio" id="autopay" name="basicpay" value="Automated" />
                                        <p> Premium - $159.99</p>
                                    </span>
                                </div>
                                <input type="hidden" id="cardholdername" />
                                <input type="hidden" id="cardnumber" />
                                <input type="hidden" id="cardexpmonth" />
                                <input type="hidden" id="cardexpyear" />
                                <input type="hidden" id="luid" />
                                <div className='fullwidth pt-10'>
                                    <span className="sbuscjeckbox" ><input type="checkbox" id="subscriptiontoken" onChange={showhidesubsopt} /> I have a discount coupon code</span>
                                    <div className='tokenholder nodisplay' id="substoken">
                                        <input type="text" id="subscribecode" placeholder='Your Code' />
                                        <button onClick={applysubscriptioncode}>Apply</button>
                                    </div>
                                </div>
                                <div class="fullwidth pt-20 nodisplay" id="updateplanbtn">
                                    <div class="cardformfiled noborder">
                                        <button class="savebtnround " onClick={updateplantext}>Update</button>
                                    </div>
                                </div>

                            </div>
                            <div className='fullwidth'>
                                <div className='one-full nopadding'>
                                    <p className='captiontext nxtpaydt nomargin'>Payment date</p>
                                </div>
                                <div className='one-full nopadding'>
                                    <p className='captiontext nextpay nomargin'>Payment date</p>
                                </div>
                                <div className='one-full nopadding'>
                                    <p className='captiontext' id="balanceamt">Disclosure Remaining</p>
                                </div>
                                <div className='one-full nopadding'>
                                    <p className='captiontext nomargin' id="balanceamt">Get More Disclosure</p>
                                </div>
                                <div className='fullwidth  planbox packagebox'>
                                    <label>Select Disclosure Quantity </label>
                                    <select id="plandetails" onChange={showUpdate}></select>
                                </div>
                                <div className='fullwidth pt-10'>
                                    <span className="sbuscjeckbox" ><input type="checkbox" id="disclostoken" onChange={showdisclosertoken} /> I have a discount coupon code</span>
                                    <div className='tokenholder nodisplay' id="disclosuretoken">
                                        <input type="text" id="disclosercodevalue" placeholder='Your Code' />
                                        <button onClick={applydisclosurecode}>Apply</button>
                                    </div>
                                </div>
                                <div class="fullwidth pt-20 nodisplay" id="updatediscloserbtn">
                                    <div class="cardformfiled noborder">
                                        <button class="savebtnround " onClick={updatedisclosure}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='one-third'>
                            <div className='order-summery-block'>
                                <h3 className='specialheading'>Order Summery</h3>
                                <div className='fullwidth ptb-20'>
                                    <h2 className="pull-left smallheadidng" id="planname">Basic</h2>
                                    <h2 className="pull-right smallheadidng pricetag" id="planprice"></h2>
                                    <div className='qutyrow'>
                                        <h3>Discount Code(Plan)</h3>
                                        <h3 className='valueval' id="plandiscount">Not Applied</h3>
                                    </div>
                                    <div className='qutyrow'>
                                        <h3>Disclosures QTY:</h3>
                                        <h3 className='valueval' id="quanprint">0</h3>
                                    </div>

                                    <div className='qutyrow'>
                                        <h3>Disclosures Price:</h3>
                                        <h3 className='valueval' id="valtodisplay">$00</h3>
                                    </div>
                                    <div className='qutyrow'>
                                        <h3>Discount Code(Disclosure)</h3>
                                        <h3 className='valueval' id="disclosurediscount">Not Applied</h3>
                                    </div>
                                    <div className='qutyrow nodisplay' id="cvvblock">
                                        <h3 className='mt-7'>Insert your CVV</h3>
                                        <input className='inputbox' type="text" id="cvvnumber" />
                                    </div>

                                    <div className='totalamt topborder'>
                                        <h2 className="pull-left smallheadidng">Total Due Today</h2>
                                        <h2 className="pull-right smallheadidng pricetag" id="totalprice">$00.00</h2>
                                    </div>

                                </div>
                                <div className='fullwidth purchasebtn text-center nodisplay' id="savedatabtn">
                                    <button className='fullwidthbtn' onClick={sendForsave}>Purchase</button>
                                </div>

                            </div>
                            <div class="fullwidth">
                                <p class="nopadding nomargin smalltext">Your Active Payment Method</p>
                                <div class="informationfields cardformfiled">
                                    <select name="discloserqun" id="disqun">

                                    </select>
                                </div>
                                <div className='fullwidth'>
                                    <button className='onlytextbtn pull-left' onClick={openEditArea}>Edit Payment Method</button>



                                </div>
                            </div>

                            <div className='popform nodisplay' id="editcard">
                                <div className='halfwidth ' >
                                    <div className='fullwidth ptb-10'>
                                        <button className='onlytextbtn pull-right' id="closeedit" onClick={closeeditpart}>X</button>
                                    </div>
                                    <div className='headingbar' >
                                        <h3>Edit Your Payment Method</h3>
                                        <img src={Cardimg} alt="Payment Method" />
                                    </div>
                                    <div className="formholder" >
                                        <div class="formholder">
                                            <div className='row halfdeivision'>

                                                <div className='one-half plr-5'>
                                                    <div className='formfieldholder nopadding'>
                                                        <input type="text" id="cardholdname" name="cardholdname" placeholder="Card Holder FullName" />
                                                    </div>
                                                </div>
                                                <div className='one-half plr-5'>
                                                    <div className='formfieldholder nopadding'>
                                                        <input type="text" id="cardnumberedit" name="cardnumberedit" placeholder="Credit Card Number" />
                                                    </div>
                                                </div>


                                            </div>



                                            <div class="fullwidth">
                                                <div className='row halfdeivision'>

                                                    <div className='one-full nopadding'>

                                                        <div className='one-third plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <select id="expmonth">
                                                                    <option value="">Select a month</option>
                                                                    <option value="01">01 ( JAN )</option>
                                                                    <option value="02">02 ( FEB )</option>
                                                                    <option value="03">03 ( MAR )</option>
                                                                    <option value="04">04 ( APR )</option>
                                                                    <option value="05">05 ( MAY )</option>
                                                                    <option value="06">06 ( JUN )</option>
                                                                    <option value="07">07 ( JUL )</option>
                                                                    <option value="08">08 ( AUG )</option>
                                                                    <option value="09">09 ( SEP )</option>
                                                                    <option value="10">10 ( OCT )</option>
                                                                    <option value="11">11 ( NOV )</option>
                                                                    <option value="12">12 ( DEC )</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='one-third plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <select id="expyear"><option value="24">2024</option><option value="25">2025</option><option value="26">2026</option><option value="27">2027</option><option value="28">2028</option><option value="29">2029</option><option value="30">2030</option><option value="31">2031</option><option value="32">2032</option><option value="33">2033</option><option value="34">2034</option><option value="35">2035</option><option value="36">2036</option><option value="37">2037</option><option value="38">2038</option><option value="39">2039</option><option value="40">2040</option><option value="41">2041</option><option value="42">2042</option><option value="43">2043</option><option value="44">2044</option><option value="45">2045</option><option value="46">2046</option><option value="47">2047</option><option value="48">2048</option><option value="49">2049</option><option value="50">2050</option><option value="51">2051</option><option value="52">2052</option><option value="53">2053</option><option value="54">2054</option><option value="55">2055</option><option value="56">2056</option><option value="57">2057</option><option value="58">2058</option><option value="59">2059</option><option value="60">2060</option><option value="61">2061</option><option value="62">2062</option><option value="63">2063</option><option value="64">2064</option><option value="65">2065</option><option value="66">2066</option><option value="67">2067</option><option value="68">2068</option><option value="69">2069</option><option value="70">2070</option><option value="71">2071</option><option value="72">2072</option><option value="73">2073</option></select>
                                                            </div>
                                                        </div>
                                                        <div className='one-third plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <input type="text" id="cvv" name="cvv" placeholder="CVV" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="fullwidth">
                                                    <div className='row halfdeivision'>

                                                        <div className='one-full plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <input type="text" id="address" name="address" placeholder="Address" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="fullwidth">
                                                    <div className='row halfdeivision'>
                                                        <div className='five-two plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <input type="text" id="city" name="city" placeholder="City" />
                                                            </div>
                                                        </div>
                                                        <div className='one_half_five plr-5'>
                                                            <div className='formfieldholder nopadding'>

                                                                <select id="statead" name="statead">
                                                                    <option value="">Select a state</option>
                                                                    <option value="AL">AL</option>
                                                                    <option value="AA">AA</option>
                                                                    <option value="AE">AE</option>
                                                                    <option value="AK">AK</option>
                                                                    <option value="AP">AP</option>
                                                                    <option value="AZ">AZ</option>
                                                                    <option value="AR">AR</option>
                                                                    <option value="CA">CA</option>
                                                                    <option value="CO">CO</option>
                                                                    <option value="CT">CT</option>
                                                                    <option value="DC">DC</option>
                                                                    <option value="DE">DE</option>
                                                                    <option value="FL">FL</option>
                                                                    <option value="GA">GA</option>
                                                                    <option value="GU">GU</option>
                                                                    <option value="HI">HI</option>
                                                                    <option value="ID">ID</option>
                                                                    <option value="IL">IL</option>
                                                                    <option value="IN">IN</option>
                                                                    <option value="IA">IA</option>
                                                                    <option value="KS">KS</option>
                                                                    <option value="KY">KY</option>
                                                                    <option value="LA">LA</option>
                                                                    <option value="ME">ME</option>
                                                                    <option value="MD">MD</option>
                                                                    <option value="MA">MA</option>
                                                                    <option value="MI">MI</option>
                                                                    <option value="MN">MN</option>
                                                                    <option value="MS">MS</option>
                                                                    <option value="MO">MO</option>
                                                                    <option value="MT">MT</option>
                                                                    <option value="NE">NE</option>
                                                                    <option value="NV">NV</option>
                                                                    <option value="NH">NH</option>
                                                                    <option value="NJ">NJ</option>
                                                                    <option value="NM">NM</option>
                                                                    <option value="NY">NY</option>
                                                                    <option value="NC">NC</option>
                                                                    <option value="ND">ND</option>
                                                                    <option value="OH">OH</option>
                                                                    <option value="OK">OK</option>
                                                                    <option value="OR">OR</option>
                                                                    <option value="PA">PA</option>
                                                                    <option value="PR">PR</option>
                                                                    <option value="RI">RI</option>
                                                                    <option value="SC">SC</option>
                                                                    <option value="SD">SD</option>
                                                                    <option value="TN">TN</option>
                                                                    <option value="TX">TX</option>
                                                                    <option value="UT">UT</option>
                                                                    <option value="VI">VI</option>
                                                                    <option value="VT">VT</option>
                                                                    <option value="VA">VA</option>
                                                                    <option value="WA">WA</option>
                                                                    <option value="WV">WV</option>
                                                                    <option value="WI">WI</option>
                                                                    <option value="WY">WY</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='one_half_five plr-5'>
                                                            <div className='formfieldholder nopadding'>
                                                                <input type="text" id="zipad" name="zipad" placeholder="Zip" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="fullwidth pt-50">
                                                    <div class="cardformfiled noborder">
                                                        <button class="savebtn" onClick={updateCardInfo}>Save</button>
                                                        <button class="clearbtn" onClick={clearformcard}>Clear All</button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >
    )
}
