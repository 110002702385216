import React from 'react'
import Toppart from '../Component/toppart';
import Signin_Topbar from './Signin_Topbar';
import $ from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import Footer from './Footer';
import { useState, useEffect, useRef } from 'react';
import useScript from './Usescript';

export default function Login() {
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const scriptLoaded = useScript('https://www.google.com/recaptcha/api.js');

    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }


      setTimeout(loadrecaptcha,900);
      function loadrecaptcha(){
       if (scriptLoaded) {
         if (recaptchaRef.current && !recaptchaRef.current.hasChildNodes()) {
           window.grecaptcha.render(recaptchaRef.current, {
             sitekey: '6Lcl-ugpAAAAAJsE8OcHRwDyjwVcYc7qv3Wkqs2z',
             callback: handleChange
           });
         }
       }
       }


    function userlogin(){
        if (!captchaValue) {
            alert('Please complete the reCAPTCHA');
            return;
          }else{
      
        var uemail = $('#useremail').val();
        var upass = $('#pass').val();
       if(uemail===''){
        alert('Please insert your registered email.');
       }else if(upass===''){
        alert('Please insert your password');
       }else{
        if(validateEmail(uemail)){
            
            var obj = {
                useremail:uemail,
                userpass:upass
                
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/login.php',
                data: obj,
                cache: false,
                success: function(result) { 
                            
                   if(result.trim()!=="fail"){   
                        
                      
                    
                        var arr= result.split('|');
                        var uname = arr[0];
                        var uemail = arr[1];
                        var upass = arr[2];
                        var uid = arr[3];
                        var uplan = arr[4];
                        var updisclo = arr[5];
                        var accstatus = arr[6];

                        if(accstatus==='Non_Approved'){
                            navigate('/noapproved');
                        }else if(accstatus==='Not_Uploaded'){
                            navigate('/dashboardbasic');
                        }else{
                                                                                                                                                                                                 
                        localStorage.setItem('uname',uname);
                        localStorage.setItem('uemail',uemail);
                        localStorage.setItem('uid',uid);
                        localStorage.setItem('uplan',uplan);
                        localStorage.setItem('uplan',uplan);
                        localStorage.setItem('updisclo',updisclo);
                        localStorage.setItem('extraval','0');
                        if(uplan==='Basic Pro'){
                            navigate('/clientlist');
                        }else if(uplan==='Premium'){
                            navigate('/clientlist');
                        }else{
                            navigate('/inputquote');
                        }
                       
                        }
                   }else{
                      alert('Plese check your email or password.'); 
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
       }
    }
    }

    function gowithgoogle(){
        window.location.href='https://app.readydisclosure.com/google-oauth.php';
    }
    const handleChange = (value) => {
            setCaptchaValue(value);
      };
  return (
    <div>
       <Toppart/>
         <Signin_Topbar />
         <section>
        <div className='reg_small_container'>
            <div className='sign_in_form mb-80'>
                <div className='sign_in_cont'>
                    <button className='googlebutton' onClick={gowithgoogle}>Continue with Google
</button>
                    <p className='ordiv'>OR</p>
                    <div className='loginform'>
                        <input type="email" name="useremail" id="useremail" placeholder="name@yourdomain.com" />
                    </div>
                    <div className='loginform'>
                        <input type="password" name="pass" id="pass" placeholder="Password"/>
                    </div>
                   <div ref={recaptchaRef}></div>
                    
                    <div className='loginform'>
                        <button onClick={userlogin}>Login</button>
                    </div>
                    </div>
                    <p className='infotext'>Forgot password? <Link to="/forgotpass">Click Here</Link></p>
                    <div className='bottomredirection'>
                      
                    </div> 
                </div>  
            </div>
        
    </section>
            <Footer />
    </div>
  )
}
