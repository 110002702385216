import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Update_profile() {
    useEffect(()=>{
        var luid = localStorage.getItem('uid');
        $('#luid').val(luid);

        var obj = {
            LUID: luid,
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/get_profile_data.php',
            data: obj,
            cache: false,
            success: function (result) {

                if (result !== 'fail') {

                    //4242|03|27|Jun 09, 2024
                    var arr = result.split('|');
                    var fname = arr[0];
                    var lname = arr[1];
                    var bphone = arr[2];
                    var emailval = arr[3];
                    var pass = arr[4];
                    var businessname = arr[5];
                    var businessadd = arr[6];
                    var city = arr[7];
                    var statead = arr[8];
                    var zipval = arr[9];
                    var website = arr[10];
                    
                    $('#fname').val(fname);
                    $('#lname').val(lname);                   
                    $('#emailval').val(emailval);
                    $('#businessname').val(businessname);
                    $('#businessadd').val(businessadd)
                    $('#city').val(city);
                    $('#statead').val(statead);
                    $('#zipval').val(zipval);
                    $('#website').val(website);
                    $('#bphone').val(bphone);

                   

                 



                } else {
                    alert('Plese check your email.');
                }
            },
        });
    })
    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }
    function formatPhoneNumber(inputid) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    function updateprofile(){
        var fname = $('#fname').val();
        var lname = $('#lname').val();
        var phone = $('#phone').val();
        var emailval = $('#emailval').val();
        var pass = $('#pass').val();
        var businessname = $('#businessname').val();
        var businessadd = $('#businessadd').val();
        var city = $('#city').val();
        var statead = $('#statead').val();
        var zipval = $('#zipval').val();
        var website = $('#website').val();
        var bphone = $('#bphone').val();
        var luid = localStorage.getItem('uid')
        if(fname===''){
            offTheBar('fname')
        }else if(lname===''){
            offTheBar('lname')
        }else if(businessname===''){
            offTheBar('businessname')
        }else if(businessadd===''){
            offTheBar('businessadd')
        }else if(city===''){
            offTheBar('city')
        }else if(statead===''){
            offTheBar('statead')
        }else if(zipval===''){
            offTheBar('zipval')
        }else if(website===''){
            offTheBar('website')
        }else if(bphone===''){
            offTheBar('bphone')
        }else{
            var obj = {
                fname:fname,
                lname:lname,                        
                businessname:businessname,
                businessadd:businessadd,
                city:city,
                statead:statead,
                zipval:zipval,
                website:website,
                bphone:bphone,
                LUID:luid
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/update_profile.php',
                data: obj,
                cache: false,
                success: function(result) { 
                   if(result!=='fail'){   
                    window.location.href = 'https://app.readydisclosure.com/updatemessage.php'
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
        }
    }

    function updateaccount(){
        var luid = localStorage.getItem('uid')
        var emailval = $('#emailval').val();
        var pass = $('#pass').val();
        
        if(emailval===''){
            offTheBar('emailval')
        }else if(pass===''){
            offTheBar('pass')
        }else{
            var obj = {
                emailval:emailval,
                pass:pass,
                LUID:luid                      
               
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/update_account.php',
                data: obj,
                cache: false,
                success: function(result) { 
                   if(result!=='fail'){   
                    window.location.href = 'https://app.readydisclosure.com/updatemessage.php'
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
        }
    }
    function openaccountedit(){
        $('#acsetting').css('display','block');
    }
    function closeAccount(){
        $('#acsetting').css('display','none');
    }
  return (
    <div>
      <DashboardTopbar />
            <section className="contentarea mb-60">
            <div className='container'>
                <div className="toprow">
                    <div className="bigbox">
                        <button className="srchbtn bluebtn" >Profile</button>
                        <button className="srchbtn" ><Link to="/updatebillinginfo">Billing</Link></button>
                        <button className="srchbtn"  ><Link to="/invoicelisting">Invoices</Link></button>
                    </div>

                </div>
                <div className='reg_row'>
                    <div className='two-third'>
                        <div className='fullwidth ' id="editcard">
                            <div className='headingbar' >
                                <h3>Edit Your Profile</h3>
                                
                            </div>
                            <div className="formholder" >
                                <div className="formholder">
                                    <h3>Your Personal Info</h3>
                                    <div className='row halfdeivision'>

                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="fname" name="fname" placeholder="First Name" />
                                            </div>
                                        </div>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="lname" name="lname" placeholder="Last name" />
                                            </div>
                                        </div>
                                        
                                    </div>


                                   
 
                                    <h3>Your Business Info</h3>
                                    <div className='row halfdeivision'>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="businessname" name="businessname" placeholder="Business Name" />
                                            </div>
                                        </div>
                                        <div className='two-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="businessadd" name="businessadd" placeholder="Business Address" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row halfdeivision'>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="city" name="city" placeholder="City" />
                                            </div>
                                        </div>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <select id="statead" name="statead">
                                                    <option value="">Select a state</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AA">AA</option>
                                                    <option value="AE">AE</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AP">AP</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="AR">AR</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="GU">GU</option>
                                                    <option value="HI">HI</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="IA">IA</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MD">MD</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NY">NY</option>
                                                    <option value="NC">NC</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="PR">PR</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VI">VI</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="zipval" name="zipval" placeholder="Zip" />
                                            </div>
                                        </div>
                                    </div>



                                    <div className='row halfdeivision'>
                                        <div className='one-third plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="website" name="website" placeholder="Website" />
                                            </div>
                                        </div>

                                        <div className='one_half_five plr-5'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="tel" id="bphone" name="bphone" maxlength="14" onInput={() => { formatPhoneNumber('bphone') }} placeholder="(xxx) xxx-xxxx" />
                                            </div>
                                            <input type="hidden" name="luid" id="luid"/>
                                        </div>
                                    </div>




                                    <div className="fullwidth pt-50">
                                        <div className="cardformfiled noborder">
                                            <button className="savebtn" onClick={updateprofile}>Update</button>
                                           

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='one-third'>
                        <button className='openbtn' onClick={openaccountedit}>Account Setting</button>
                        <div className='fullwidth nodisplay pt-50' id="acsetting">
                        <h3>Your Account Info</h3>
                                    <div className='row halfdeivision'>
                                        <div className='one-full pb-25'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="email" id="emailval" name="emailval" placeholder="Email" />
                                            </div>
                                        </div>

                                        <div className='one-full pb-25'>
                                            <div className='formfieldholder nopadding'>
                                                <input type="text" id="pass" name="pass" placeholder="Password" />
                                            </div>
                                        </div>
                                        <div className="fullwidth pt-50">
                                        <div className="cardformfiled noborder">
                                            <button className="savebtn" onClick={updateaccount}>Update</button>
                                            <button class="clearbtn" onClick={closeAccount}>Close</button>
                                            
                                        </div>
                                    </div>

                                    </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
    </div>
  )
}
