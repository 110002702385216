import React from 'react'
import { useEffect, useState } from 'react';
import DashboardTopbar from './DashboardTopbar';
import Footer from './Footer';
import Addmarchent from '../images/addmarchent.png';
import Searchicon from '../images/searchicon.png';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function Search_clientlist() {
  const navigate = useNavigate();
  var searchtext = localStorage.getItem('searchstring');
  var marchent_id = '';
    const [luid, setluid] = useState(localStorage.getItem('uid'))
    const [clientlistarr, setClientList] = useState([]);
    var ptype = localStorage.getItem('uplan');

    useEffect(() => {
      const laodListRecord = () => {
          const obj = { LUID: luid,profile_type:ptype,search:searchtext }
          $.ajax({
              type: 'POST',
              url: 'https://app.readydisclosure.com/allclientlist_search.php',
              data: obj,
              cache: false,
              success: function (result) {
                  if (result !== 'fail') {
                      setClientList(result);
                  } else {
                      $('#searchfailtext').text('No result found');
                      $('#searchfailtext').css('display','block');
                  }
              },
          });
      };

      const timer = setTimeout(laodListRecord, 1000);
      return () => clearTimeout(timer);
  }, [luid])

  function redirectToquote(qid, id) {
    localStorage.setItem('marchid', id);
    localStorage.setItem('mrchid', qid);
    navigate('/inputquote');
}

function goforpreview(id) {
    localStorage.setItem('marchantid', id);
    navigate('/merchant_preview');
}

function gofordelete(id) {
    var obj = {
        dcId_PK: id
    }
    $.ajax({
        type: 'POST',
        url: 'https://app.readydisclosure.com/deletemarchent.php',
        data: obj,
        cache: false,
        success: function (result) {
            if (result !== 'fail') {
                navigate('/succdessdelete');
            } else {
                alert('We cannot find any record.');
            }
        },
    });
}

const renderClientList = (clientlistarr, index) => {
    const { marchent_fname, marchent_lname, business_phone, business_email, created_at, primary_key, merchant_id } = clientlistarr;
    let firstLetter = marchent_fname.charAt(0);
    if(marchent_lname!==''&&marchent_lname!==null){
        var secondletter = marchent_lname.charAt(0);
    }else{
        var secondletter="";
    }
    
    let shortname = firstLetter + secondletter
    let fullname = marchent_fname + ' ' + marchent_lname;
    const handlePreview = () => {
        goforpreview(primary_key);
    };
    const handledelete = () => {
        gofordelete(primary_key);
    };
    const handlecreatedisclosure = () => {
        redirectToquote(merchant_id, primary_key);
    };
    const handledisclosurelist = () => {
        localStorage.setItem('selusername', fullname);
        localStorage.setItem('marchidkey', primary_key);
        localStorage.setItem('marchid', merchant_id);
        navigate('/disclosurelist');
    }
    return (
        <div
            key={index}
            id={`content${index}`}
            className='one-full mtb-5'>
            <div className='inforow pb-26 bb-1'>
                <div className='listcheckholder'><input type="checkbox" id="selectall" /></div>
                <div className='one-fifth-smlarge pos-rel'><div class="usr-initials">{shortname}</div><span className='namespan linkspan' onClick={handlePreview}>{marchent_fname} {marchent_lname}</span></div>
                <div className='one-fifth_extrasmall'>{business_phone}</div>
                <div className='one-fifth-large'>{business_email}</div>
                <div className='one-fifth'>{created_at}</div>
                <div className='deletecell greenlabel'><i className='fas fa-plus' onClick={handlecreatedisclosure} title="Generate Disclosure"></i></div><div className='deletecell greenlabel'><i className='fas fa-list' onClick={handledisclosurelist} title="Disclosure List"></i></div>
            </div>

        </div>
    );
};


  return (
    <div>
       <DashboardTopbar />
            <div className='reg_container'>
                <div className='reg_row'>
                    <div className='one-full mtb-5'>
                        <div className='controlrow'>
                           
                            <span className='pt-6'>
                                
                                <span className='captionlist'>Search result for {searchtext}</span>
                            </span>
                            
                        </div>
                        <div className='inforow coverrow'>
                            <div className='listcheckholder'><input type="checkbox" id="selectall" /></div>
                            <div className='one-fifth-smlarge'>Name</div>
                            <div className='one-fifth'>Phone</div>
                            <div className='one-fifth-large'>Email</div>
                            <div className='one-fifth'>Created</div>
                        </div>
                    </div>
                </div>
                <div className='reg_row' id="clientlistarea">
                    {clientlistarr.map((clientlistarr, index) => renderClientList(clientlistarr, index))}
                    <h2 id="searchfailtext" className='nodisplay notetext'></h2>
                </div>
            </div>
            <Footer />
    </div>
  )
}
