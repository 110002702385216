import React, { useEffect, useState } from 'react';
import DashboardTopbar from './DashboardTopbar';
import Footer from './Footer';
import $ from 'jquery';

export default function Notification() {
    const [luid, setLuid] = useState(localStorage.getItem('uid'));
    const [notifications, setNotifications] = useState([]);
    const [keyval, setKeyval] = useState('');
    var keyvalue = '';

    useEffect(() => {
        const loadNotification = () => {
            const obj = { LUID: luid };

            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/notifications_user.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result !== 'fail') {
                        setNotifications(result);
                    } else {
                        alert('Please check your email.');
                    }
                },
            });
        };

        const timer = setTimeout(loadNotification, 1000);

        return () => clearTimeout(timer);
    }, [luid]);

    const opencontent = (id) => {
        const selectedNotification = notifications[id];
        setKeyval(selectedNotification.pk);
        keyvalue = selectedNotification.pk;
       
        const updatedNotifications = notifications.map((notification, index) => {
            if (index === id) {
                return { ...notification, readstatus: "true" };
            }
            return notification;
        });
        setNotifications(updatedNotifications);
        updatelist();
    };

    const updatelist = () => {
        const obj = { LUID: luid, keyvalue:keyvalue };

        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/updatenotification.php',
            data: obj,
            cache: false,
            success: function (result) {
                if (result !== 'fail') {
                } else {
                    alert('cannot update.');
                }
            },
        });
    };

    const renderNotification = (notification, index) => {
        const { msgfrom, date, heading, content, readstatus } = notification;
        return (
            <div
                key={index}
                id={`content${index}`}
                className={`one-full topbottongap border listitem ${readstatus === "false" ? '' : 'fadeblock'}`}
            >
                <div className="inforow">
                    <p className="smallcodeptag pull-left text-left">message from {msgfrom}</p>
                    <p className="smallcodeptag pull-right text-right">{date}</p>
                </div>
                <h2 className="notificationheading">{heading}</h2>
                <p className="headingcontent" style={{ display: readstatus === "false" ? 'none' : 'inline-block' }}>
                    {content}
                </p>
                <p className='nodisplay' id="pk">{notification.pk}</p>
                {readstatus === "false" && (
                    <div className="readmorelist">
                        <button onClick={() => opencontent(index)}>Read More</button>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <DashboardTopbar />
            <div className='reg_container_small'>
                <div className='reg_row' id="notificationlist">
                    {notifications.map((notification, index) => renderNotification(notification, index))}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
