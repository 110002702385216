import React, { useEffect } from 'react'
import Toppart from '../Component/toppart';
import Topbar from './Topbar';
import Footer from './Footer';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


export default function Sign_up_two() {
    function formatPhoneNumber(inputid) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    useEffect(() => {
        document.getElementById('bphone').addEventListener('keydown', function(event) {
            // Allow backspace
            if (event.key === 'Backspace') {
                return true;
            }
            
            // Prevent typing non-digit characters
            if (!/\d/.test(event.key)) {
                event.preventDefault();
            }
        });
        return () => {
            // Code to run when component is unmounted
        };
    }, []);




    const navigate = useNavigate();
    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("showemptyfield");
    }
    
    function gotosavebusiness(){
        var businessname = $('#businessname').val();
        var businessadd = $('#businessadd').val();
        var bcity = $('#bcity').val();
        var bstate = $('#bstate').val();
        var bzip = $('#bzip').val();
        var website = $('#website').val();
        var bphone = $('#bphone').val();
        var luid = localStorage.getItem('uid')
        if(businessname===''){
            offTheBar('businessname')
        }else if(businessadd===''){
            offTheBar('businessadd')
        }else if(bcity===''){
            offTheBar('bcity')
        }else if(bstate===''){
            offTheBar('bstate')
        }else if(bzip===''){
            offTheBar('bzip')
        }else if(website===''){
            offTheBar('website')
        }else if(bphone===''){
            offTheBar('bphone')
        }else{
            var obj = {
                businessname:businessname,
                businessadd:businessadd,
                bcity:bcity,
                bstate:bstate,
                bzip:bzip,
                website:website,
                bphone:bphone,
                luid:luid
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/signup_steptwo.php',
                data: obj,
                cache: false,
                success: function(result) { 
                   if(result!='fail'){   
                    localStorage.setItem('uid',result);
                   navigate('/signthree');
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
        }

    }
  return (
    <div>
      <Toppart/>
      <Topbar/>
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className="one-third nopadding" id="tabcrtaccount">
                    <div className="tabscontent firsttab ">
                        <div className="tabnumber">1</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">1. Create Your Account</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabidinfo">
                    <div className="tabscontent ">
                        <div className="tabnumber activetabnumber">2</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext activetebtext">2. Business Information</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabbilling">
                    <div className="tabscontent lasttab ">
                        <div className="tabnumber">3</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">3. Billing Information</p>
                </div>
            </div>
        </div>
    </section>
    <section>
        <h4 className='sectionname'>Business Information</h4>
        <div className='reg_container'>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="businessname" name="businessname" placeholder="Business Name"/>
                    </div>
                </div>
                <div className='two-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="businessadd" name="businessadd" placeholder="Business Address"/>
                    </div>
                </div>
                
                
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="bcity" name="bcity" placeholder="City"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <select id="bstate" name="bstate">
                                        <option value="">Select a state</option>
                                        <option value="AL">AL</option>
                                        <option value="AA">AA</option>
                                        <option value="AE">AE</option>
                                        <option value="AK">AK</option>
                                        <option value="AP">AP</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                    </select>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="bzip" name="bzip" placeholder="Zip"/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="website" name="website" placeholder="Website"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="tel" id="bphone" name="bphone"  maxlength="14" onInput={()=>{formatPhoneNumber('bphone')}} placeholder="(xxx) xxx-xxxx"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    
                </div>
            </div>
            <div className="nextbtndiv pt-60 pb-200">
                        <button id="ntbtn" className="nextbtn" onClick={gotosavebusiness}>NEXT<i
                                className="fas fa-caret-right"></i></button>
                        
                    </div>
        </div>
        
    </section>
    <Footer/>
    </div>
  )
}
