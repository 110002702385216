import React from 'react'
import Nomenutopbar from './Nomenutopbar';
import Footer from './Footer';
import UplaodIcon from '../images/uploadicon.png';
import { useEffect } from 'react';
import $ from 'jquery';
import Loaderimg from '../images/loaderimg.svg';
import {Link, useNavigate} from 'react-router-dom';

export default function Dashboardbasic() {
  const navigate = useNavigate();
useEffect(()=>{
  var luid = localStorage.getItem('uid');
  if(luid===null){
    navigate('/login');
  }
  $('#useridval').val(luid);
  const fileInput = document.getElementById('userimg');
  fileInput.addEventListener('change', function() {
    var len  = this.files.length;
    $('.fileinfo').html('');
    var htms = '<h3> You have selected total '+len+' files';
    $('.fileinfo').append(htms);
    $('.fileinfo').addClass('activefileinfo')
    for(var i=0;i<len;i++){
    const selectedFile = this.files[i];
    const fileName = selectedFile.name;
    var slnum = i+1;
    var htm = '<p>('+slnum+'} '+fileName+'</p>';
    $('.fileinfo').append(htm);
    }    
});

})

function clearFileInput() {
  const fileInput = document.getElementById('userimg');
  fileInput.value = ''; // Reset the value to an empty string
  $('.fileinfo').html('');
  $('.fileinfo').removeClass('activefileinfo');
 
}
function notconfirm(){
  $('#popconf').css('display','none')
}
function openconfirmpop(){
  $('#popconf').css('display','block')
}

function saveuploadedfile(){
  var formData = new FormData();
  notconfirm();

        // Append files to FormData
        var files = $('#userimg')[0].files;
        if(files.length>0){
          $('.loader').css('display','block');
        for (var i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }

        // Append other data to FormData
        formData.append('userid', $('#useridval').val());

        // Send the formData using AJAX
        $.ajax({
            url: 'https://app.readydisclosure.com/uplaod_documents.php',
            type: 'POST',
            data: formData,
            processData: false, // Prevent jQuery from processing the data
            contentType: false, // Prevent jQuery from setting contentType
            success: function(response) {
                if(response==='success'){                  
                  $('.loader').css('display','none');
                    clearFileInput();
                    alert("Your documents submited successfully.");
                }else{
                  $('.loader').css('display','none');
                    clearFileInput();
                    alert("Your documents submited successfully.");
                }
              
                // Handle success
            },
            error: function(xhr, status, error) {
                console.error('Error uploading files:', error);
                // Handle error
            }
        });
      }else{
        alert('You have not select any files to upload.')
      }
}
 
  return (
    <div>
      <Nomenutopbar/>
      <section className='loader'>
        <div className='loader-holder'>
          <img src={Loaderimg} alt="Loader"/>
        </div>
      </section>
      <section>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont text-center'>
                <h3 class="formheading">Please Read</h3>
                <p className='infotext'>It will take 1-3 business days to activate your account from the moment we receive your funding contract. </p>
                <p className='infotext'>You can also email your contact to <br/><a href="mailto:support@readydisclosure.com">support@readydisclosure.com </a></p>
                    <div className='loginform uplaodform'> 
                       <div className='fileinfo'>

                       </div>
                       <input type="hidden" id="useridval"/>
                        <input type="file" name="userimg" id="userimg" multiple/>
                        <div className='uploadicon'>
                            <img src={UplaodIcon} alt="Upload user icon"/>
                            <p>Drag and Drop</p>
                        </div>
                    </div>
                    <div className='buttonholder uplaods pt-30 pb-100'>
                       <button id="uplaodfiles" className='bluebtn' onClick={openconfirmpop}>Upload</button>
                       <button id="clearupload" className='redbtn' onClick={clearFileInput}>Clear</button>
                    </div>
                   
                    
                </div>  
            </div>
        </div>
    </section>
            <Footer />
        <section className='poptopay' id="popconf">
             <div className='confplate'>
                <h2 className='confheading'>Please Confirm</h2>
                <p className='conftext'>Please confirm you have uploaded the correct contract</p>
                <div className='buttonholder'>
                  <button className='confbutton' onClick={saveuploadedfile}>Confirm</button>
                  <button className='confclose' onClick={notconfirm}>Close</button>
                </div>
             </div>
        </section>
    </div>
  )
}
