import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';
import { Link } from 'react-router-dom';

export default function Invoice_listing() {
    var luid = localStorage.getItem('uid');
    let billsource ="https://app.readydisclosure.com/all_invoices.php?LUID="+luid
    function fixIframeHeight() {
        setTimeout(setHeightreport,5000);
       }
       function setHeightreport(){
           var iframes = document.getElementById('all_invoices'); 
           
           var iframeDocumentfx = iframes.contentDocument || iframes.contentWindow.document;
           var iframeContentHeightfx = (iframeDocumentfx.body.scrollHeight)+80;
           iframes.style.height = iframeContentHeightfx + 'px';
       }

  return (
    <div>
      <DashboardTopbar />
        <section className="contentarea">
            <div className='container'>
            <div className="toprow">
                    <div className="bigbox">
                    <button className="srchbtn" ><Link to="/updateprofile">Profile</Link></button>
                        <button className="srchbtn" ><Link to="/updatebillinginfo">Billing</Link></button>
                        <button className="srchbtn bluebtn">Invoices</button>
                    </div>

                </div>
                <div className='reg_row'>
                    <div className='one-full'>
                    <iframe id="all_invoices" class="report_iframe" onLoad={fixIframeHeight} src={billsource} title="Check the full report of your payments" ></iframe> 
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
  )
}
