import React from 'react'
import Toppart from '../Component/toppart';
import Topbar from './Topbar';
import Legallinks from './Legallinks';
import Footer from './Footer';
import $ from 'jquery';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Sign_up_three() {

    var selectedplan = 'Automated';

    var quantity = 0;

    var discountcode = '';
    var discountpercent = 0;
    var subscpriptionprice = 0;
    var actualtotalprice = 0;
    var discountsts = 'false';

    var totalprice = 0;
    var subsdiscountpercent = 0;
    var actualsubscriptionprice = 0;
    var subsdiscountcode = '';
    var subsdiscountsts = 'false';

    var disclosercodests = 'false';
    var actualdisclosureprice = 0;
    var quantityPrice = 0;
    var discloserpercent = 0;
    var disclosercode = '';


    var basicpricelist = [
        {
            "qty": "1",
            "price": "8",
            "display": "$8"
        },
        {
            "qty": "5",
            "price": "39",
            "display": "$39"
        },
        {
            "qty": "25",
            "price": "187",
            "display": "$187"
        },
        {
            "qty": "50",
            "price": "360",
            "display": "$360"
        },
        {
            "qty": "100",
            "price": "700",
            "display": "$700"
        }
    ]

    var automate = [
        {
            "qty": "1",
            "price": "11",
            "display": "$11"
        },
        {
            "qty": "5",
            "price": "54",
            "display": "$54"
        },
        {
            "qty": "25",
            "price": "256",
            "display": "$256"
        },
        {
            "qty": "50",
            "price": "495",
            "display": "$495"
        },
        {
            "qty": "100",
            "price": "950",
            "display": "$950"
        }
    ]

    var basicpro = [
        {
            "qty": "1",
            "price": "9",
            "display": "$9"
        },
        {
            "qty": "5",
            "price": "44",
            "display": "$44"
        },
        {
            "qty": "25",
            "price": "212",
            "display": "$212"
        },
        {
            "qty": "50",
            "price": "410",
            "display": "$410"
        },
        {
            "qty": "100",
            "price": "800",
            "display": "$800"
        }
    ]

    useEffect(() => {
        selectradio('basicpay');
    })


    function getPlanValue() {
        const radioButtons = document.getElementsByName('basicpay');

        // Loop through radio buttons to find the selected one
        for (let i = 0; i < radioButtons.length; i++) {
            if (radioButtons[i].checked) {
                // Return the value of the selected radio button
                var optval = radioButtons[i].value;
                return optval;
            }
        }
    }

    function storeCouponCode() {
        disclosercode = $('#disclosercodevalue').val();
        var luid = localStorage.getItem('uid');
        var initvalue = $('#disclosequantity').val();
        var arr = initvalue.split('|');    
        var quantity = arr[0];
        if (disclosercode === '') {
            alert('You have not entered any code.')
        } else {
            var obj = {
                LUID: luid,
                discountcode: disclosercode,
                quantity:quantity

            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/check_discount_discloser_code.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result.trim() !== '0') {
                        discloserpercent = result;
                        disclosercodests = 'true'
                        alert('Discount coupon for disclosure has been applied successfully')
                        calculatedisclosurediscount();
                    } else {
                        disclosercodests = 'false';
                        discloserpercent = 0;
                        alert('Your code is not valid for this package.');
                    }
                },
            });
        }
    }


    function applysubscriptioncode() {
        subsdiscountcode = $('#subscribecode').val();
        var luid = localStorage.getItem('uid');
       
        if (subsdiscountcode === '') {
            alert('You have not entered any code.')
        } else {
            var obj = {
                LUID: luid,
                discountcode: subsdiscountcode
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/check_discount_subscribe_code.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result.trim() !== 'fail') {
                        subsdiscountpercent = result;
                        subsdiscountsts = 'true'
                        alert('Discount coupon for new plan has been applied successfully')
                        calculatediscount();

                    } else {
                        subsdiscountsts = 'false';
                        subsdiscountpercent = 0;
                        $('#plandiscount').text('Not Applied')
                        alert('Your code is not valid.');
                    }
                },
            });
        }
    }


    function selectradio(radiobt) {
        $("#" + radiobt).prop("checked", true);
        if (radiobt === 'basicpay') {
            selectedplan = "Basic";
            subscpriptionprice = 99.99;
            actualsubscriptionprice = subscpriptionprice;
            calculatediscountonchange();
        }
        if (radiobt === 'autopay') {
            selectedplan = "Premium";
            subscpriptionprice = 159.99;
            actualsubscriptionprice = subscpriptionprice;
            calculatediscountonchange();
        }
        if (radiobt === 'basicpro') {
            selectedplan = "Basic Pro";
            subscpriptionprice = 129.99;
            actualsubscriptionprice = subscpriptionprice;
            calculatediscountonchange();
        }

    }

    function calculatediscountonchange() {
        if (subsdiscountpercent > 0) {
            var discount = parseInt(subsdiscountpercent);
            var discountamt = (discount / 100) * actualsubscriptionprice;
            subscpriptionprice = actualsubscriptionprice - discountamt;
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice + '/mo');
            totalprice = subscpriptionprice + quantityPrice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
            setPlanPackage();

        } else {
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice + '/mo');
            totalprice = subscpriptionprice + quantityPrice;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
            setPlanPackage();
        }
    }


    function setPlanPackage() {
        if (selectedplan === 'Basic') {
            setBasic();
        }
        if (selectedplan === 'Premium') {
            setAutomated();
        }
        if (selectedplan === 'Basic Pro') {
            setBasicPro();
        }
    }


    function setBasic() {
        var subsprice = '$99.99/mo';
        $('#planname').text('Basic');
        $('#planprice').text(subsprice)

        $('#disclosequantity').html('');
        var len = basicpricelist.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = basicpricelist[i].display + ' For ' + basicpricelist[i].qty;
            var value = basicpricelist[i].qty + '|' + basicpricelist[i].price + '|' + basicpricelist[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#disclosequantity').append(htm);
        }
        var initval = basicpricelist[0].qty + '|' + basicpricelist[0].price + '|' + basicpricelist[0].display;

        setPriceAtLeft(initval);
    }

    function setAutomated() {
        var subsprice = '$159.99/mo';
        $('#planprice').text(subsprice);
        $('#planname').text('Premium');

        $('#disclosequantity').html('');
        var len = automate.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = automate[i].display + ' For ' + automate[i].qty;
            var value = automate[i].qty + '|' + automate[i].price + '|' + automate[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#disclosequantity').append(htm);
        }
        var initval = automate[0].qty + '|' + automate[0].price + '|' + automate[0].display;

        setPriceAtLeft(initval);
    }

    function setBasicPro() {
        var subsprice = '$129.99/mo';
        $('#planprice').text(subsprice);
        $('#planname').text('Basic Pro');

        $('#disclosequantity').html('');
        var len = basicpro.length;
        for (var i = 0; i < len; i++) {
            var texttodisplay = basicpro[i].display + ' For ' + basicpro[i].qty;
            var value = basicpro[i].qty + '|' + basicpro[i].price + '|' + basicpro[i].display;
            var htm = '<option value="' + value + '">' + texttodisplay + '</option>';
            $('#disclosequantity').append(htm);
        }
        var initval = basicpro[0].qty + '|' + basicpro[0].price + '|' + basicpro[0].display;

        setPriceAtLeft(initval);
    }

    function setPriceAtLeft(intval) {
        var arr = intval.split('|');

        quantity = arr[0];
        quantityPrice = arr[1];
        actualdisclosureprice = quantityPrice;

        calculatedisclosurediscount();
    }

    function calculatedisclosurediscount() {
        if (discloserpercent > 0) {
            var discount = parseInt(discloserpercent);
            var discountamt = (discount / 100) * actualdisclosureprice;
            quantityPrice = actualdisclosureprice - discountamt;
            var price = parseInt(quantityPrice);
            var subsprice = parseInt(subscpriptionprice);
            actualtotalprice = price + subscpriptionprice;
            var printtotalprice = formatMoney(actualtotalprice);
            var pricetodisplay = formatMoney(price);
            $('#quanprint').text(quantity);
            $('#valtodisplay').text(pricetodisplay)
            $('#totalprice').text(printtotalprice);
        } else {
            var price = parseInt(quantityPrice);
            var subsprice = parseInt(subscpriptionprice);
            actualtotalprice = price + subscpriptionprice;
            var printtotalprice = formatMoney(actualtotalprice);
            var pricetodisplay = formatMoney(price);
            $('#quanprint').text(quantity);
            $('#valtodisplay').text(pricetodisplay);
            $('#totalprice').text(printtotalprice);
        }

    }


    function calculatediscount() {
        if (subsdiscountpercent > 0) {

            var discount = parseInt(subsdiscountpercent);
            var discountamt = (discount / 100) * actualsubscriptionprice;
            subscpriptionprice = actualsubscriptionprice - discountamt;
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice + '/mo');
            var price = parseInt(quantityPrice);
            totalprice = subscpriptionprice + price;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);

        } else {
            var printplanprice = formatMoney(subscpriptionprice);
            $('#planprice').text(printplanprice + '/mo');
            var price = parseInt(quantityPrice);
            totalprice = subscpriptionprice + price;
            var printtotalprice = formatMoney(totalprice);
            $('#totalprice').text(printtotalprice);
        }
    }

    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return 'Invalid number';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }

    function startsetPriceAtLeft(val) {
        var setval = $('#' + val).val();
        setPriceAtLeft(setval);
    }

    function makeCheck(chk, valpara) {
        var chkval = $('#' + chk).attr('chkval')
        if (chkval == 'notcheckd') {
            $('#' + chk).addClass('activecheckbox');
            $('#' + chk).removeClass('inactivecheckbox');
            $('#' + chk).attr('chkval', 'nowchecked')
            $('#' + valpara).text('checked');

        } else {
            $('#' + chk).removeClass('activecheckbox');
            $('#' + chk).addClass('inactivecheckbox');
            $('#' + chk).attr('chkval', 'notcheckd');
            $('#' + valpara).text('');
        }
    }

    function shownotice(ms) {
        var element = document.getElementById(ms);
        element.classList.add("activenotice");
    }

    function offNotice(ms) {
        var element = document.getElementById(ms);
        element.classList.remove("activenotice");
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }


    function compareDates() {
        var inputYear = parseInt($('#expyear').val());
        var inputvalidyear = 2000+inputYear;
        var inputMonth = parseInt($('#expmonth').val());
        let currentDate = new Date();

        // Extract the current year and month
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
        console.log(currentMonth + ' :' + inputMonth + ' : ' + currentYear + ' : ' + inputYear)

        // Compare the input date's year and month with the current year and month
        if (inputvalidyear < currentYear || (inputvalidyear === currentYear && inputMonth < currentMonth)) {
            showAlert('Date of expiration of your card is invalid.')
        } else if (inputvalidyear > currentYear || (inputvalidyear === currentYear && inputMonth > currentMonth)) {
            goforPayment();
        } else {
            goforPayment();
        }
    }

    function goforPayment() {
        var aggreedcheck = $('#firstchkval').text();
        var understanding = $('#secondchkval').text();
        if (aggreedcheck == 'checked' && understanding == 'checked') {
            var cardholdname = $('#cardholdname').val();
            var cardnumber = $('#cardnumber').val();
            var address = $('#address').val();
            var statead = $('#statead').val();
            var zipad = $('#zipad').val();
            var expmonth = $('#expmonth').val();
            var cityval = $('#city').val();
            var expyear = $('#expyear').val();
            var luid = localStorage.getItem('uid')
            var cvv = $('#cvv').val();

            if (cardholdname == '') {
                offTheBar('cardholdname')
            } else if (cardnumber == '') {
                offTheBar('cardnumber')
            } else if (address == '') {
                offTheBar('address')
            } else if (statead == '') {
                offTheBar('statead')
            } else if (zipad == '') {
                offTheBar('zipad')
            } else if (expmonth == '') {
                offTheBar('expmonth')
            } else if (expyear == '') {
                offTheBar('expyear')
            } else if (cityval == '') {
                offTheBar('city')
            } else if (cvv == '') {
                offTheBar('cvv')
            } else {
                var obj = {
                    cardholdname: cardholdname,
                    cardnumber: cardnumber,
                    address: address,
                    statead: statead,
                    city: cityval,
                    zip: zipad,
                    expmonth: expmonth,
                    expyear: expyear,
                    cvv: cvv,
                    LUID: luid,
                    selectedplan: selectedplan,
                    subscpriptionprice: subscpriptionprice,
                    quantity: quantity,
                    quantityPrice: quantityPrice,
                    totalprice: actualtotalprice,
                    aggreedcheck: aggreedcheck,
                    understanding: understanding,
                    discloserpercent: discloserpercent,
                    disclosercode: disclosercode,
                    actualdisclosureprice: actualdisclosureprice,
                    subsdiscountpercent: subsdiscountpercent,
                    subsdiscountcode: subsdiscountcode,
                    actualsubscriptionprice: actualsubscriptionprice

                }
                var alldataarr = [];
                alldataarr.push(obj)
                let arrayString = JSON.stringify(alldataarr);
                localStorage.setItem('dataarray', arrayString);

                window.location.href = 'https://app.readydisclosure.com/confirmpayment.php';

                /* $.ajax({
                     type: 'POST',
                     url: 'https://app.readydisclosure.com/confirmpayment.php',
                     data: obj,
                     cache: false,
                     success: function (result) {
                         console.log(result);
                         if (result != 'fail') {
                             alert('You have registered successfully')
                         } else {
                             alert('Plese check your email.');
                         }
                     },
                 });*/
            }
        } else {
            alert('You have to understand the time factor for execution of the plan and also have to agree with our terms and conditions');
        }

    }

    function closepop() {
        $('.poptopay').css('display', 'none');
    }

    function showAlert(str) {
        $('.poptopay').css('display', 'block');
        $('#msg').text(str);
    }
    return (
        <div>
            <Toppart />
            <Topbar />
            <section className="tabssection desktopnav">
                <div className="reg_container">
                    <div className="reg_row">
                        <div className="one-third nopadding" id="tabcrtaccount">
                            <div className="tabscontent firsttab ">
                                <div className="tabnumber">1</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext">1. Create Your Account</p>
                        </div>
                        <div className="one-third nopadding dimmer" id="tabidinfo">
                            <div className="tabscontent ">
                                <div className="tabnumber">2</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext">2. Business Information</p>
                        </div>
                        <div className="one-third nopadding dimmer" id="tabbilling">
                            <div className="tabscontent lasttab ">
                                <div className="tabnumber activetabnumber">3</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext activetebtext">3. Billing Information</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h4 className='sectionname'>Complete 3 Easy Steps</h4>

                <div className='reg_container'>
                    <div className="registerpart">
                        <div className='row division'>
                            <div className='one-full'><h4 className='sectioncaption'>Step 1: Choose Your Plan</h4></div>
                            <div className='one-half'>

                                <span class="pos-rel">
                                    <button className='radiocoverbutton' onClick={() => { selectradio('basicpay') }}>&nbsp;</button>
                                    <input type="radio" id="basicpay" name="basicpay" value="basic" />
                                    <p>Basic</p>
                                    <button class="infobtn" onMouseOver={() => { shownotice('basicnotice') }} onMouseOut={() => { offNotice('basicnotice') }}>i</button>
                                    <p class="noticetext" id="basicnotice">This is basic plan. You can subscribe it to understand our service quality</p>
                                </span>

                                <span class="pos-rel">
                                    <button className='radiocoverbutton' onClick={() => { selectradio('basicpro') }}>&nbsp;</button>
                                    <input type="radio" id="basicpro" name="basicpay" value="Automated" />
                                    <p> Basic Pro</p>
                                    <button class="infobtn" onMouseOver={() => { shownotice('pronotice') }} onMouseOut={() => { offNotice('pronotice') }}>i
                                    </button>
                                    <p class="noticetext" id="pronotice">This is basic pro plan. You can subscribe it to understand our service quality. </p>
                                </span>



                                <span class="pos-rel">
                                    <button className='radiocoverbutton' onClick={() => { selectradio('autopay') }}>&nbsp;</button>
                                    <input type="radio" id="autopay" name="basicpay" value="Automated" />
                                    <p> Premium</p>
                                    <button class="infobtn" onMouseOver={() => { shownotice('autonotice') }} onMouseOut={() => { offNotice('autonotice') }}>i
                                    </button>
                                    <p class="noticetext" id="autonotice">This is premium plan. You can subscribe it to understand our service quality. </p>
                                </span>



                            </div>
                        </div>
                        <div className='row division'>
                            <div className='one-full'><h4 className='sectioncaption'>Step 2: Select Disclosure Quantity</h4></div>
                            <div className='one-half'>
                                <div className='formfieldholder nopadding shortfield'>
                                    <select id="disclosequantity" onChange={() => { startsetPriceAtLeft('disclosequantity') }}></select>
                                </div>
                            </div>
                        </div>
                        <div className='row halfdeivision'>
                            <div className='one-full'><h4 className='sectioncaption'>Step 3: Enter Payment Information</h4></div>
                            <div className='one-half plr-5'>
                                <div className='formfieldholder nopadding'>
                                    <input type="text" id="cardholdname" name="cardholdname" placeholder="Card Holder FullName" />
                                </div>
                            </div>
                            <div className='one-half plr-5'>
                                <div className='formfieldholder nopadding'>
                                    <input type="text" id="cardnumber" name="cardnumber" placeholder="Credit Card Number" />
                                </div>
                            </div>


                        </div>
                        <div className='row halfdeivision'>

                            <div className='one-half nopadding'>

                                <div className='one-third plr-5'>
                                    <div className='formfieldholder nopadding'>
                                        <select id="expmonth">
                                            <option value="">MONTH</option>
                                            <option value="01">01 ( JAN )</option>
                                            <option value="02">02 ( FEB )</option>
                                            <option value="03">03 ( MAR )</option>
                                            <option value="04">04 ( APR )</option>
                                            <option value="05">05 ( MAY )</option>
                                            <option value="06">06 ( JUN )</option>
                                            <option value="07">07 ( JUL )</option>
                                            <option value="08">08 ( AUG )</option>
                                            <option value="09">09 ( SEP )</option>
                                            <option value="10">10 ( OCT )</option>
                                            <option value="11">11 ( NOV )</option>
                                            <option value="12">12 ( DEC )</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='one-third plr-5'>
                                    <div className='formfieldholder nopadding'>
                                        <select id="expyear"><option value="">YEAR</option><option value="24">2024</option><option value="25">2025</option><option value="26">2026</option><option value="27">2027</option><option value="28">2028</option><option value="29">2029</option><option value="30">2030</option><option value="31">2031</option><option value="32">2032</option><option value="33">2033</option><option value="34">2034</option><option value="35">2035</option><option value="36">2036</option><option value="37">2037</option><option value="38">2038</option><option value="39">2039</option><option value="40">2040</option><option value="41">2041</option><option value="42">2042</option><option value="43">2043</option><option value="44">2044</option><option value="45">2045</option><option value="46">2046</option><option value="47">2047</option><option value="48">2048</option><option value="49">2049</option><option value="50">2050</option><option value="51">2051</option><option value="52">2052</option><option value="53">2053</option><option value="54">2054</option><option value="55">2055</option><option value="56">2056</option><option value="57">2057</option><option value="58">2058</option><option value="59">2059</option><option value="60">2060</option><option value="61">2061</option><option value="62">2062</option><option value="63">2063</option><option value="64">2064</option><option value="65">2065</option><option value="66">2066</option><option value="67">2067</option><option value="68">2068</option><option value="69">2069</option><option value="70">2070</option><option value="71">2071</option><option value="72">2072</option><option value="73">2073</option></select>
                                    </div>
                                </div>
                                <div className='one-third plr-5'>
                                    <div className='formfieldholder nopadding'>
                                        <input type="text" id="cvv" name="cvv" placeholder="CVV" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row halfdeivision'>
                            <div className='one-full plr-5'>
                                <div className='formfieldholder nopadding'>
                                    <input type="text" id="address" name="address" placeholder="Address" />
                                </div>
                            </div>
                        </div>
                        <div className='row halfdeivision'>
                            <div className='five-two plr-5'>
                                <div className='formfieldholder nopadding'>
                                    <input type="text" id="city" name="city" placeholder="City" />
                                </div>
                            </div>
                            <div className='one_half_five plr-5'>
                                <div className='formfieldholder nopadding'>

                                    <select id="statead" name="statead">
                                        <option value="">Select a state</option>
                                        <option value="AL">AL</option>
                                        <option value="AA">AA</option>
                                        <option value="AE">AE</option>
                                        <option value="AK">AK</option>
                                        <option value="AP">AP</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                    </select>
                                </div>
                            </div>
                            <div className='one_half_five plr-5'>
                                <div className='formfieldholder nopadding'>
                                    <input type="text" id="zipad" name="zipad" placeholder="Zip" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='one-third'>
                        <div className='order-summery-block'>
                            <h3 className='specialheading'>Order Summary</h3>
                            <div className='fullwidth ptb-20'>
                                <h2 className="pull-left smallheadidng" id="planname">Basic</h2>
                                <h2 className="pull-right smallheadidng pricetag" id="planprice">$99/mo</h2>
                                <div className='qutyrow'>
                                    <h3>Discount Code:</h3>
                                    <div className='formdiv'>
                                        <input type="text" id="subscribecode" placeholder='Code' />
                                        <button onClick={applysubscriptioncode}>Apply</button>
                                    </div>
                                </div>
                                <div className='qutyrow'>
                                    <h3>Disclosures QTY:</h3>
                                    <h3 className='valueval' id="quanprint">1000</h3>
                                </div>
                                <div className='qutyrow'>
                                    <h3>Disclosures Price:</h3>
                                    <h3 className='valueval' id="valtodisplay">$4000</h3>
                                </div>
                                <div className='qutyrow'>
                                    <h3>Discount Code:</h3>
                                    <div className='formdiv'>
                                        <input type="text" id="disclosercodevalue" placeholder='Code' />
                                        <button onClick={storeCouponCode}>Apply</button>
                                    </div>
                                </div>
                                <div className='totalamt'>
                                    <h2 className="pull-left smallheadidng">Total Due Today</h2>
                                    <h2 className="pull-right smallheadidng pricetag" id="totalprice">$29.99</h2>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </section >
            <section className='footer'>
                <div className='container'>
                    <div className='reg_row'>
                        <div className='two_third_makecenter pb-50'>
                            <div className='checkholder'>
                                <button className='coverbutton' onClick={() => { makeCheck('firstcheck', 'firstchkval') }}>&nbsp;</button>
                                <p className='footerinfotext inactivecheckbox' id="firstcheck" chkval="notcheckd">By checking this box  you agree to the be bound by the<a href="https://readydisclosure.com/terms-of-service/" target="_blank">Terms of Service Agreement</a>, <a href="https://readydisclosure.com/privacy-policy/" target="_blank">Privacy Policy </a> and to receive sms and email communication from <a href="https://readydisclosure.com/ " target="_blank">readydisclosure.com</a></p>
                                <p id="firstchkval" className='nodisplay'></p>
                            </div>

                            <div className='checkholder'>
                                <button className='coverbutton' onClick={() => { makeCheck('secondcheck', 'secondchkval') }}>&nbsp;</button>
                                <p className='footerinfotext mt-0 inactivecheckbox' id="secondcheck" chkval="notcheckd">I understand it will take 1-3 business days to activate my account after Ready Disclosure
                                    receives my funding contract. </p>
                                <p id="secondchkval" className='nodisplay'></p>
                            </div>
                        </div>
                        <div className='one-full'>
                            <div className="nextbtndiv pt-30 pb-200 newwidth">
                                <button id="ntbtn" className="nextbtn" onClick={compareDates}>Purchase</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="poptopay" id="alertpop">
                <div className='shortwhitebase'>
                    <h2>Alert</h2>
                    <p id="msg"></p>
                    <div className='buttonholder'>
                        <button onClick={closepop}>Okay</button>
                    </div>
                </div>
            </section>


            <Footer />
        </div >
    )
}
