import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import Footer from './Footer';
import { useEffect } from 'react';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';

export default function Merchant_preview() {
  const navigate = useNavigate();

  useEffect(() => {
    var newmarid = localStorage.getItem('marchantid');
    var ptype = localStorage.getItem('uplan');
    var obj = {
      dcId_PK: newmarid,
      profile_type:ptype
    }
    $.ajax({
      type: 'POST',
      url: 'https://app.readydisclosure.com/getidformerchant.php',
      data: obj,
      cache: false,
      success: function (result) {
        if (result !== 'fail') {
          var arr = result.split('|');
          $('#business_name').text(arr[0]);
          $('#business_dba').text(arr[1]);
          $('#marchent_fname').text(arr[2]);
          $('#marchent_lname').text(arr[3]);
          $('#business_email').text(arr[4]);
        } else {
          alert('We cannot find any record.');
        }
      },
    });
  })
  function gobacktolist() {
    navigate('/clientlist');
  }
  function goforedit() {
    var uplan = localStorage.getItem('uplan');
    if(uplan==='Basic Pro'){
      navigate('/direct_editmerchant');
  }else if(uplan==='Premium'){
    localStorage.setItem('targetpage','preview');
    navigate('/direct_premium_editmerchant');
  }    
  }
  return (
    <div>
      <DashboardTopbar />
      <div className='reg_container'>
        <div className='reg_row'>
          <div className='one-full mtb-5'>
            <div className='controlrow'>
              <span className='pr-15'>
                <button className='filterfillbutton fillcolor' onClick={gobacktolist}> Back</button>
              </span>
              <span>
                <button className='exportbutton nofillcolor' onClick={goforedit}> Edit</button>
              </span>
            </div>
          </div>
        </div>

        <div className='reg_row pt-50'>
          <div className='one-full mtb-5'>

            <div className="formfieldholder newlabel">
              <div className="one-full pos-rel">
                <span className="boldfont">Business Name : </span>
                <span className="desctext" id="business_name"></span>
              </div>             
            </div>

            <div className="formfieldholder newlabel">
              <div className="one-full pos-rel">
                <span className="boldfont">Business DBA : </span>
                <span className="desctext" id="business_dba"></span>
              </div>             
            </div>

            <div className="formfieldholder newlabel">
              <div className="one-full pos-rel">
                <span className="boldfont">Merchant First Name : </span>
                <span className="desctext" id="marchent_fname"></span>
              </div>             
            </div>

            <div className="formfieldholder newlabel">
              <div className="one-full pos-rel">
                <span className="boldfont">Merchant Last Name : </span>
                <span className="desctext" id="marchent_lname"></span>
              </div>             
            </div>

            <div className="formfieldholder newlabel">
              <div className="one-full pos-rel">
                <span className="boldfont">Email Address : </span>
                <span className="desctext" id="business_email"></span>
              </div>             
            </div>

            

          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}
