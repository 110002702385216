import React from 'react'
import { useEffect, useState } from 'react';
import DashboardTopbar from './DashboardTopbar';
import Footer from './Footer';
import Addmarchent from '../images/addmarchent.png';
import Searchicon from '../images/searchicon.png';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function Client_list() {
   
    const navigate = useNavigate();
    var marchent_id = '';
    const [luid, setluid] = useState(localStorage.getItem('uid'))
    const [clientlistarr, setClientList] = useState([]);
    var ptype = localStorage.getItem('uplan');
    
    useEffect(() => {
        const laodListRecord = () => {
            const obj = { LUID: luid,profile_type:ptype }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/allclientlist.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result !== 'fail') {
                        setClientList(result);
                    } else {
                        alert('We cannot find any record.');
                    }
                },
            });
        };

        const timer = setTimeout(laodListRecord, 1000);
        return () => clearTimeout(timer);
    }, [luid])

    function redirectToquote(qid, id) {
        localStorage.setItem('marchid', id);
        localStorage.setItem('mrchid', qid);
        navigate('/inputquote');
    }

    function goforpreview(id) {
        localStorage.setItem('marchantid', id);
        navigate('/merchant_preview');
    }

    function gofordelete(id) {
        var obj = {
            dcId_PK: id
        }
        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/deletemarchent.php',
            data: obj,
            cache: false,
            success: function (result) {
                if (result !== 'fail') {
                    navigate('/succdessdelete');
                } else {
                    alert('We cannot find any record.');
                }
            },
        });
    }

    const renderClientList = (clientlistarr, index) => {
        const { marchent_fname, marchent_lname, business_phone, business_email, created_at, primary_key, merchant_id } = clientlistarr;
        let firstLetter = marchent_fname.charAt(0);
        if(marchent_lname!==''&&marchent_lname!==null){
            var secondletter = marchent_lname.charAt(0);
        }else{
            var secondletter="";
        }
        
        let shortname = firstLetter + secondletter
        let fullname = marchent_fname + ' ' + marchent_lname;
        const handlePreview = () => {
            goforpreview(primary_key);
        };
        const handledelete = () => {
            gofordelete(primary_key);
        };
        const handlecreatedisclosure = () => {
            redirectToquote(merchant_id, primary_key);
        };
        const handledisclosurelist = () => {
            localStorage.setItem('selusername', fullname);
            localStorage.setItem('marchidkey', primary_key);
            localStorage.setItem('marchid', merchant_id);
            navigate('/disclosurelist');
        }
        return (
            <div
                key={index}
                id={`content${index}`}
                className='one-full mtb-5'>
                <div className='inforow pb-26 bb-1'>
                    <div className='listcheckholder'><input type="checkbox" id="selectall" /></div>
                    <div className='one-fifth-smlarge pos-rel'><div class="usr-initials">{shortname}</div><span className='namespan linkspan' onClick={handlePreview}>{marchent_fname} {marchent_lname}</span></div>
                    <div className='one-fifth_extrasmall'>{business_phone}</div>
                    <div className='one-fifth-large'>{business_email}</div>
                    <div className='one-fifth'>{created_at}</div>
                    <div className='deletecell greenlabel'><i className='fas fa-plus' onClick={handlecreatedisclosure} title="Generate Disclosure"></i></div><div className='deletecell greenlabel'><i className='fas fa-list' onClick={handledisclosurelist} title="Disclosure List"></i></div>
                </div>

            </div>
        );
    };
    function addMarchent() {
       var uplan = localStorage.getItem('uplan');
       if(uplan==='Basic Pro'){
        $('#addpopup').addClass('activepop');
        createuniqueid();
    }else if(uplan==='Premium'){
        navigate('/premium_form');
    }
       
    }
    function closePOP() {
        $('#addpopup').removeClass('activepop')
    }
    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }
    function saveMarchentRecord() {
        var business_name = $('#business_name').val();
        var business_dba = $('#business_dba').val();
        var marchent_fname = $('#marchent_fname').val();
        var marchent_lname = $('#marchent_lname').val();
        var business_email = $('#business_email').val();
        var luid = localStorage.getItem('uid')
        if (business_name === '') {
            offTheBar('business_name')
        } else if (marchent_fname === '') {
            offTheBar('marchent_fname')
        } else if (business_dba === '') {
            offTheBar('business_dba')
        } else if (marchent_lname === '') {
            offTheBar('marchent_lname')
        } else if (business_email === '') {
            offTheBar('business_email')
        } else {
            var obj = {
                business_name: business_name,
                business_dba: business_dba,
                marchent_fname: marchent_fname,
                marchent_lname: marchent_lname,
                business_email: business_email,
                luid: luid,
                marchent_id: marchent_id,
                business_phone: 'Not available'
            }
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/save_new_merchant_record.php',
                data: obj,
                cache: false,
                success: function (result) {
                    if (result.trim() !== "fail") {
                        localStorage.setItem('marchid', result);
                        navigate('/inputquote');
                    } else {
                        alert('Plese check your email.');
                    }
                },
            });
        }
    }
    function generateUniqueId(existingIds) {
        let uniqueId;
        do {
            uniqueId = Math.floor(100000 + Math.random() * 900000);
        } while (existingIds.includes(uniqueId));
        return uniqueId;
    }
    function createuniqueid() {
        var existingIds = [123456, 654321, 111111];
        var newUniqueId = generateUniqueId(existingIds);
        $('#marchentid').text(newUniqueId);
        marchent_id = newUniqueId;
        localStorage.setItem('mrchid', newUniqueId);
    }

    function makesearch(){
        var searchsrt = $('#searchmarchent').val();
        if(searchsrt===''){
            alert('Please insert your search text')
        }else{
            localStorage.setItem('searchstring',searchsrt);
            navigate('/searchclient_list');
        }
        
    }
    return (
        <div>
            <DashboardTopbar />
            <div className='reg_container'>
                <div className='reg_row'>
                    <div className='one-full mtb-5'>
                        <div className='controlrow'>
                            <span className='pt-6'><button onClick={addMarchent} className='asddmarchentbtn'><img src={Addmarchent} alt="Add Marchent" /></button></span>
                            <span className='pt-6'>
                                <select>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                                <span className='captionlist'>Entries per page</span>
                            </span>
                            <div className='one-half-large searchform pr-20 force-right'>
                                <input type="text" id="searchmarchent" placeholder='Search by Email or Name' />
                                <button type="button" onClick={makesearch}><img src={Searchicon} alt="Search" /></button>
                            </div>
                        </div>
                        <div className='inforow coverrow'>
                            <div className='listcheckholder'><input type="checkbox" id="selectall" /></div>
                            <div className='one-fifth-smlarge'>Name</div>
                            <div className='one-fifth'>Phone</div>
                            <div className='one-fifth-large'>Email</div>
                            <div className='one-fifth'>Created</div>
                        </div>
                    </div>
                </div>
                <div className='reg_row' id="clientlistarea">
                    {clientlistarr.map((clientlistarr, index) => renderClientList(clientlistarr, index))}
                </div>
            </div>
            <Footer />
            <section className='addpopup' id='addpopup'>
                <div className='whitepop'>
                    <h3 className='popheading'>Add New Marchent</h3>
                    <button className="cls-btn" onClick={closePOP}>+</button>
                    <div className="smallcontainar">
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Merchant ID</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <label id="marchentid">00000000</label>
                                </div>
                            </div>
                        </div>
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Business Name</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <input type="text" id="business_name" name="business_name" placeholder="Business Name" />
                                </div>
                            </div>
                        </div>
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Business DBA</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <input type="text" id="business_dba" name="business_dba" placeholder="Business DBA" />
                                </div>
                            </div>
                        </div>
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Merchant First Name</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <input type="text" id="marchent_fname" name="marchent_fname" placeholder="Merchant First Name" />
                                </div>
                            </div>
                        </div>
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Merchant Last Name</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <input type="text" id="marchent_lname" name="marchent_lname" placeholder="Merchant Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="formfieldholder newlabel">
                            <div className="one-half pos-rel">
                                <label>Email Address</label>
                            </div>
                            <div className="one-half pos-rel">
                                <div className='inputcoverhover'>
                                    <input type="text" id="business_email" name="business_email" placeholder="Email Address" />

                                </div>
                            </div>
                        </div>
                        <div className="nextbtndiv pt-60 pb-200">
                            <button id="ntbtn" className="nextbtn" onClick={saveMarchentRecord}>NEXT<i
                                className="fas fa-caret-right"></i></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
