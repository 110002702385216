import React from 'react'
import Toppart from '../Component/toppart';
import Topbar from './Topbar';
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';
import Footer from './Footer';

export default function PreSign_up() {
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }
    const navigate = useNavigate();
    function gowithgoogle(){
        window.location.href='https://app.readydisclosure.com/google-oauth.php';
    }
    function userlogin(){
        var useremail = $('#useremail').val();
        if(validateEmail(useremail)){
        localStorage.setItem('useremail',useremail);
        navigate('/signone');
        }else{
            alert('Please insert a valid email.')
        }

    }
  return (
    <div>
         <Toppart/>
         <Topbar />
         <section className='mh-adj'>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont'>
                    <button className='googlebutton' onClick={gowithgoogle}>Continue With Google Account</button>
                    <p className='ordiv'>OR</p>
                    <div className='signinform'>
                        <input type="email" name="useremail" id="useremail" placeholder="name@yourdomain.com" />
                        <button onClick={userlogin}>Continue</button>
                    </div>
                    <p className='infotext'>By signing up I agree to the Ready Disclosure <a href="https://readydisclosure.com/privacy-policy/" target="_blank">Privacy Policy</a>  and <a href="https://readydisclosure.com/terms-of-service/" target="_blank">Terms of Service.</a></p>
                    <div className='bottomredirection'>
                      
                    </div> 
                </div>
            </div>
        </div>
    </section>
            <Footer/>
    </div>
  )
}
